// packages
import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Tooltip } from 'react-tippy';

// utilities
import cn from 'src/utilities/bem-cn';

// hooks
import useIsMobile from 'src/utilities/hooks/useIsMobile';

// components
import DragIcon from '../../../../../../components/icons/DragIcon';
import UpArrow from '../../../../../../components/icons/UpArrow';
import DownArrow from '../../../../../../components/icons/DownArrow';
import RankedOptionCheckbox from '../../../../../../components/elements/RankedOptionCheckbox';
import RankedX from '../../../../../../components/icons/RankedX';

// styles
import './styles.scss';

const className = 'ranked-option';
const el = (name, mod) => cn(className, name, mod);

// constants
const ARROW_BUTTON_SIZE = 34;
const ARROW_BUTTONS_GAP = 10;
const OPTION_LABEL_LINE_HEIGHT = 16;
const OPTION_LABEL_MAX_LINES = 3;

const RankedOption = ({
	option,
	renderImageOption,
	justDraggedOptionId,
	position = undefined,
	isOverlay = false,
	onIncreaseRanking = null,
	onDecreaseRanking = null,
	onRemoveRankedOption = null,
	unRankedOptionOnClick = null,
	overLimitErrorId = undefined,
	draggedItemIndex = null,
	swipedOptionId = null,
	setSwipedOptionId = null,
	onKeyDown = null,
	rankedOptionsAmount = 0,
	limitExceeded = false,
}) => {
	const { t } = useTranslation('main');
	const { attributes, listeners, node, setNodeRef, transform, transition, isDragging } = useSortable({
		id: option?.id,
		data: { type: 'option' },
	});

	const isMobile = useIsMobile();

	const optionLabelRef = useRef(null);

	const isOptionLabelOverflowing =
		optionLabelRef.current?.scrollHeight > OPTION_LABEL_LINE_HEIGHT * OPTION_LABEL_MAX_LINES + 2;
	const isOptionLabelTooltipEnabled = !isMobile && isOptionLabelOverflowing;
	const isOptionLabelScrollEnabled = isMobile && isOptionLabelOverflowing;

	const tooltipContent = useMemo(
		() => (isOptionLabelTooltipEnabled ? option?.label || '' : ''),
		[isOptionLabelTooltipEnabled, option?.label],
	);

	const style = {
		transition,
		transform: CSS.Transform.toString(transform),
	};

	const [showRemoveButton, setShowRemoveButton] = useState(false);
	const [showSlideOutAnimation, setShowSlideOutAnimation] = useState(false);

	const [touchStart, setTouchStart] = useState(null);
	const [touchEnd, setTouchEnd] = useState(null);

	// the required distance between touchStart and touchEnd to be detected as a swipe
	const minSwipeDistance = 10;

	useEffect(() => {
		if (swipedOptionId !== option?.id && showRemoveButton) {
			setShowRemoveButton((prevState) => {
				if (prevState) {
					return !prevState;
				}
			});
		}
	}, [swipedOptionId, option?.id, showRemoveButton]);

	const onTouchStart = (e) => {
		setTouchEnd(null);
		setTouchStart(e.targetTouches[0].clientX);
		listeners?.onTouchStart(e);
	};

	const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		const isRightSwipe = distance < -minSwipeDistance;
		if ((isLeftSwipe || isRightSwipe) && justDraggedOptionId !== option?.id) {
			if (isLeftSwipe && position) {
				setShowRemoveButton(true);
				setSwipedOptionId(option?.id);
			} else if (isRightSwipe && showRemoveButton) {
				setShowRemoveButton(false);
				setShowSlideOutAnimation(true);
				setTimeout(() => {
					setShowSlideOutAnimation(false);
				}, 250);
			}
		}
	};

	const handleIncreaseRanking = useCallback(() => {
		// * Focus the option if it's moving to the top of the list, and loses "Increase ranking" button
		if (position === 2) {
			node.current?.focus();
		}
		onIncreaseRanking(option);
	}, [position, onIncreaseRanking, option, node]);

	const handleDecreaseRanking = useCallback(() => {
		onDecreaseRanking(option);
	}, [onDecreaseRanking, option]);

	const renderPositionChange = useCallback(
		() => (
			<div
				className={el(
					'position-change-arrows',
					showRemoveButton && option?.id === swipedOptionId && 'extended',
				)}
				style={{ gap: ARROW_BUTTONS_GAP }}
			>
				{position !== 1 && (
					<button
						type="button"
						tabIndex={0}
						aria-label={t('increase-ranking', { label: option?.label })}
						className={el('up-arrow')}
						onClick={handleIncreaseRanking}
						style={{
							width: ARROW_BUTTON_SIZE,
							height: ARROW_BUTTON_SIZE,
						}}
					>
						<UpArrow />
					</button>
				)}

				<button
					type="button"
					tabIndex={0}
					aria-label={t('decrease-ranking', { label: option?.label })}
					className={el('down-arrow')}
					onClick={handleDecreaseRanking}
					style={{
						width: ARROW_BUTTON_SIZE,
						height: ARROW_BUTTON_SIZE,
					}}
				>
					<DownArrow />
				</button>

				<button
					type="button"
					tabIndex={0}
					aria-label={t('remove')}
					aria-hidden={!showRemoveButton || option?.id !== swipedOptionId}
					aria-disabled={!showRemoveButton || option?.id !== swipedOptionId}
					disabled={!showRemoveButton || option?.id !== swipedOptionId}
					className={el(
						'remove-option-button',
						showRemoveButton && option?.id === swipedOptionId && 'visible',
					)}
					onClick={() => {
						onRemoveRankedOption(option);
						setShowRemoveButton(false);
						setShowSlideOutAnimation(true);
						setTimeout(() => {
							setShowSlideOutAnimation(false);
						}, 250);
					}}
				>
					<RankedX />
				</button>
			</div>
		),
		[
			showRemoveButton,
			option,
			swipedOptionId,
			position,
			handleIncreaseRanking,
			handleDecreaseRanking,
			onRemoveRankedOption,
		],
	);

	const positionShown = isDragging && position ? draggedItemIndex + 1 : position;
	return (
		<div
			className={`${el('wrapper')}${
				showRemoveButton && position && option?.id === swipedOptionId ? ` ${el('extend-width')}` : ''
			}${showSlideOutAnimation ? ` ${el('exit-animation')}` : ''}`}
			style={style}
		>
			<div
				ref={setNodeRef}
				{...attributes}
				{...listeners}
				className={`${className}${isDragging ? ` ${el('is-dragging')}` : ''}${
					position !== undefined ? ` ${el('is-ranked')}` : ''
				}${isOverlay ? ` ${el('overlay')}` : ''}`}
				onClick={() => {
					if (unRankedOptionOnClick) {
						unRankedOptionOnClick(option);
					}
				}}
				onTouchStart={(e) => onTouchStart(e)}
				onTouchMove={onTouchMove}
				onTouchEnd={onTouchEnd}
				onKeyDown={onKeyDown}
				aria-label={`${positionShown ? `${positionShown} ` : ''}${option?.label}`}
				aria-describedby={undefined} // * Disable SR DnD instructions, since keyboard dragging is not supported when element has onClick handler
				aria-disabled={limitExceeded}
				draggable="true"
			>
				<div
					className={`${el('ranked-full-overlay')} ${
						isMobile && overLimitErrorId === option?.id ? el('overlay-active') : null
					}`}
				>
					{t('rank-list-full')}
				</div>

				<div className={el('left')}>
					<RankedOptionCheckbox position={positionShown} />

					{renderImageOption(option)}

					<div
						className={el('label-wrapper')}
						style={{
							height: OPTION_LABEL_LINE_HEIGHT * OPTION_LABEL_MAX_LINES,
							overflow: isOptionLabelScrollEnabled ? 'auto' : 'unset',
						}}
					>
						<Tooltip
							style={{ display: 'inline-flex', alignItems: 'center', flex: 1 }}
							animation="shift"
							animationFill={false}
							arrow
							theme="light"
							position="top"
							trigger="mouseenter focus"
							html={tooltipContent}
							disabled={!tooltipContent}
							delay={500}
						>
							<p
								ref={optionLabelRef}
								className={el('option-label', !isMobile && 'clamped-text')}
								style={{
									lineHeight: `${OPTION_LABEL_LINE_HEIGHT}px`,
									'-webkit-line-clamp': OPTION_LABEL_MAX_LINES,
								}}
							>
								{option?.label}
							</p>
						</Tooltip>
					</div>
				</div>

				{position === undefined ? (
					<DragIcon />
				) : (
					// * Arrow buttons offset
					<div
						style={{
							width:
								ARROW_BUTTON_SIZE * (position !== 1 ? 2 : 1) + (position !== 1 ? ARROW_BUTTONS_GAP : 0),
						}}
					/>
				)}
			</div>

			{position !== undefined && renderPositionChange()}
		</div>
	);
};

RankedOption.propTypes = {
	option: PropTypes.object,
	justDraggedOptionId: PropTypes.number,
	renderImageOption: PropTypes.func,
	position: PropTypes.number,
	isOverlay: PropTypes.bool,
	onIncreaseRanking: PropTypes.func,
	onDecreaseRanking: PropTypes.func,
	onRemoveRankedOption: PropTypes.func,
	unRankedOptionOnClick: PropTypes.func,
	overLimitErrorId: PropTypes.number,
	draggedItemIndex: PropTypes.number,
	swipedOptionId: PropTypes.number,
	setSwipedOptionId: PropTypes.func,
	onKeyDown: PropTypes.func,
	rankedOptionsAmount: PropTypes.number,
	limitExceeded: PropTypes.bool,
};

export default RankedOption;
