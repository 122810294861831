// packages
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, RadioButton } from '@upsiide/ui-components';
import { useTranslation } from 'react-i18next';

// utilities
import cn from 'src/utilities/bem-cn';
import * as misc from 'src/utilities/misc';
import useIsKeyboardNavigation from 'src/utilities/hooks/useIsKeyboardNavigation';

// components
import ImageZoom from 'src/components/new/ImageZoom';
import Input from 'src/components/shared/Input';

// styles
import './styles.scss';

const className = 'question-multi-select';
const el = (name, mod) => cn(className, name, mod);

const toggle = (c, v) => (c.indexOf(v) > -1 ? c.filter(($v) => $v !== v) : c.concat([v]));

const MultiSelect = ({
	question,
	disabled,
	value,
	options,
	onChange,
	otherSelected,
	onOtherChange,
	currentAttribute,
}) => {
	// state
	const [otherValue, setOtherValue] = useState(null);
	const [focusedOptionId, setFocusedOptionId] = useState(null);

	const isKeyboardNavigation = useIsKeyboardNavigation();

	const { t } = useTranslation('main');

	const imageOnly = misc.getQuestionSetting(question, 'image_only') === 'true';

	const optionsHaveAsset = useMemo(() => !!options?.find((option) => option.asset), [options]);

	const classNames = useMemo(() => {
		const hasOther = options.filter((o) => o.isOtherSpecify).length > 0 ? ' has-other-please-specify' : '';
		const hasNoneOfTheAbove = options.filter((o) => o.isNoneOfTheAbove).length > 0 ? ' has-none-of-the-above' : '';
		return `${className} ${!optionsHaveAsset ? ' no-asset' : ''}${
			imageOnly ? ' image-only' : ''
		}${hasOther}${hasNoneOfTheAbove}`;
	}, [imageOnly, options, optionsHaveAsset]);

	// functions
	const handleRadioButtonFocus = useCallback(
		(e, optionId) => {
			const isCheckbox = e.target.type === 'checkbox';
			if (!isKeyboardNavigation || !isCheckbox) return;
			setFocusedOptionId(optionId);
		},
		[isKeyboardNavigation],
	);

	const handleRadioButtonBlur = useCallback(() => setFocusedOptionId(null), []);

	const handleChange = (v) => {
		onChange(toggle(value || [], v));
	};

	const onOtherValueChange = (updatedValue) => {
		if (onOtherChange) {
			onOtherChange(updatedValue);
		}
		setOtherValue(updatedValue);
	};

	// life cycle
	useEffect(() => {
		setOtherValue('');
	}, [currentAttribute]);

	useEffect(() => {
		const pageContainer = document.querySelector('.scroll-container');
		if (pageContainer) {
			pageContainer.style.scrollBehavior = 'auto';
			pageContainer.scrollTo(0, 0);
		}
		// Reset scroll container
		const scrollContainer = document.querySelector(`[class*='scroll-container']`);
		if (scrollContainer) {
			scrollContainer.classList.remove('scrolled-to-bottom');
			scrollContainer.classList.remove('remove-scroll-indicator');
		}
	}, []);

	// render functions
	const renderImageOption = (option) =>
		option.asset && (
			<ImageZoom className={el('option-image-container')} imageDescription={option.label || option.value}>
				<img
					className={`${el('option-image')} ${
						misc.getQuestionSetting(question, 'option_image_cropping') === 'crop'
							? el('option-image-crop')
							: ''
					}`}
					alt={option.label || option.value}
					src={misc.getAssetVariationUrl(option, ['large', 'full', 'medium', 'thumbnail'])}
				/>
			</ImageZoom>
		);

	const renderSingleOption = (option) => {
		const isChecked = (value || []).indexOf(option.id.toString()) > -1;

		return (
			<RadioButton
				id={option.id.toString()}
				name={`question-${question.id}`} // Match the RadioGroup name
				disabled={disabled}
				checked={isChecked}
				key={option.id}
				value={option.id.toString()}
				onClick={handleChange}
				style={{
					outline: focusedOptionId === option.id ? '2px solid #005832' : 'none',
					outlineOffset: '2px',
					alignItems: option.isOtherSpecify && otherSelected ? 'flex-start' : 'center',
				}}
				inputProps={{
					onFocus: (e) => handleRadioButtonFocus(e, option.id),
					onBlur: handleRadioButtonBlur,
					'aria-label':
						option.label ||
						option?.translations?.find(({ languageCode }) => languageCode === option?.languageCode)
							?.label ||
						option.value ||
						'',
				}}
			>
				{!imageOnly || !option.asset || option.isNoneOfTheAbove || option.isOtherSpecify ? (
					<span
						className={`${el('option-label')} ${option.asset ? ' has-asset' : ''} ${
							option.isOtherSpecify && otherSelected ? ' expanded' : ''
						}`}
						aria-hidden="true"
					>
						{option.label || option.value}
					</span>
				) : null}
				{renderImageOption(option)}
				{option.isOtherSpecify && otherSelected && (
					<Input
						id="other-input-field"
						className={el('other-input')}
						placeholder={t('type-here')}
						value={otherValue}
						onChange={onOtherValueChange}
						aria-labelledby={option.id.toString()}
						aria-placeholder={t('type-here')}
						type="text"
					/>
				)}
			</RadioButton>
		);
	};

	return (
		<RadioGroup
			multiSelect
			className={classNames}
			name={`question-${question.id}`} // Add unique name to group radio buttons
			role="group"
			aria-labelledby="question-label"
		>
			{options?.map(renderSingleOption)}
		</RadioGroup>
	);
};

MultiSelect.propTypes = {
	question: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	value: PropTypes.array,
	options: PropTypes.array,
	onChange: PropTypes.func,
	otherSelected: PropTypes.bool,
	onOtherChange: PropTypes.func,
	currentAttribute: PropTypes.string,
};

export default MultiSelect;
