export default {
	'this-survey-does-not-exist': '很抱歉，該調查不存在。',
	'survey-closed': '很抱歉，該調查現已結束。',
	'survey-no-products': '本調查不涉及產品。',
	'survey-no-products-summary': '倘若這份調查是屬於您的，請在儀表板中調製產品以便繼續進行。',
	'survey-completed': '您已填完本調查。',
	'summary-placeholder': '倘若您認為這事出錯誤，請與發送此連結的人聯絡。',
	'gone-wrong': '嗯，出錯了。',
	'try-again': '請重試或稍後返回。',
	'thank-you': '謝謝您',
	'thank-you-summary': '調查已完成，您的結果已提交。',
	'thank-you-summary-redirect': '幾秒後您將被重新導向另一網址。',
	'page-not-found': '出錯了',
	'page-not-found-placeholder': '哎呀，連結已中斷',
	'tap-your-favorite': '請在兩個創意中，選擇您更喜歡的產品',
	or: '或',
	'get-started-with-questions': '幫助我們著手進行的幾個問題',
	continue: '繼續',
	retry: '重試',
	'tell-us-how-you-feel-about-this-product': '請告訴我們您對該產品的感受',
	begin: '開始',
	previewTheProductLabel: '預覽產品',
	nextImageLabel: '下一張圖片',
	previousImageLabel: '上一張圖片',
	productImageLabel: '產品圖片',
	acceptLabel: '接受',
	closeImagePreviewLabel: '關閉圖片預覽',
	'how-would-you-describe-this-product-to-a-friend': '您會如何向朋友介紹這款產品？',
	'type-your-response-here': '在此輸入您的回答',
	'sum-of-total-characters': '${SUM} 個字元，共 ${TOTAL} 個字元',
	'submit-comment': '提交評論',
	'reset-comments': '重置評論',
	'increase-ranking': '增加排名以 {{label}}',
	'decrease-ranking': '降低排名以 {{label}}',
	edit: '編輯',
	remove: '刪除',
	'how-do-you-feel-about-this-part': '您對這部分的感受為何？',
	'tap-on-an-area-and-choose-an-emoji': '點按某個區域，然後選擇一個表情符號',
	'im-finished': '完成了',
	maintenance: '維護',
	'maintenance-mode': 'Upsiide 目前正在進行維護。請盡快返回查看。',
	'please-specify': '請詳述',
	'monadic-split-statement': '在接下來的螢幕上，我們會向您展示一項構想。請仔細審視，因為我們會詢問相關的問題。',
	disapproval: '不以为然',
	anger: '愤怒',
	sad: '沮丧',
	joy: '欢乐',
	love: '喜爱',
	trust: '信任',
	hope: '希望',
	proud: '自豪',
	amazed: '惊叹',
	surprised: '惊讶',
	guilt: '内疚',
	curious: '好奇',
	jealous: '嫉妒',
	anticipation: '期盼',
	optimistic: '乐观',
	anxious: '焦虑',
	fear: '害怕',
	despair: '绝望',
	disbelief: '怀疑',
	cynicism: '玩世不恭',
	regret: '后悔',
	disgust: '厌恶',
	annoyed: '恼怒',
	aggressive: '积极进取',
	shocked: '感到震驚',
	choose: '选择 {{amount}}',
	'up-to': '选择的数量最多不超过 {{amount}}',
	unlimited: '选择任意多项',
	'single-select': '选择一个',
	number: '输入数字',
	'number-between-min-max': '输入一个介于 {{min}} 和 {{max}} 之间的数字',
	'number-min': '输入一个不小于 {{min}} 的数字',
	'number-max': '输入一个不大于 {{max}} 的数字',
	'open-ended-pledge':
		'本調查包含需要您用自己的話來回答的問題。您的回答會受到自動檢查以確保符合我們的品質要求。您是否同意繼續進行？ ',
	'open-ended-pledge-accept': '是，我同意',
	'open-ended-pledge-decline': '否，我不同意',
	'rh-1-label': '以下哪一項不屬於體育運動？',
	'rh-1-o-1': '籃球',
	'rh-1-o-2': '網球',
	'rh-1-o-3': '餅乾',
	'rh-1-o-4': '曲棍球',
	'rh-2-label': '2+3 等於多少？',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': '以下哪一項是指水果？',
	'rh-3-o-1': '牛奶',
	'rh-3-o-2': '巧克力',
	'rh-3-o-3': '蛋',
	'rh-3-o-4': '蘋果',
	'open-end-max-length': '抱歉，輸入的答案太長',
	'heatmap-click-image': '點按該圖像以便補充評論',
	'heatmap-click-image-no-comments': '點按該圖像上任意位置',
	'heatmap-click-image-product-card': '點按該卡片以便補充評論',
	'heatmap-click-image-product-card--no-comment': '點按該卡片上任意位置',
	'heatmap-type-comment': '輸入評論',
	'heatmap-add-comment': '新增評論',
	cancel: '取消',
	skip: '跳过',
	ranked: '排名',
	top: '頂部選項',
	'tap-or-drag': '點擊或拖放即可排名',
	unranked: '未排名',
	reset: '重置',
	'no-more-options': '沒有更多選項可供放置',
	'rank-list-full': '排名已滿，取消您的選擇之一的排名',
	'expanded-image': '擴展後的圖像',
	'expand-the-image': '放大圖像',
	'type-here': '在此輸入',
	'continue-survey': '繼續調查',
	'place-a-point-to-comment': '點按以補充評論',
};
