// React & Redux
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

// Localization
import { withTranslation } from 'react-i18next';

// Animation
import { motion } from 'framer-motion';

// BEM
import cn from 'src/utilities/bem-cn';

// Styles
import './styles.scss';

// Components
import Loader from 'src/components/elements/Loader';
import Message from 'src/components/shared/Message';
import ButtonFixed from 'src/components/elements/ButtonFixed';

// Images
import imgUnknown from 'public/images/icon-thinking-unknown.png';
import imgAbout from 'public/images/icon-rocket.png';

// Actions, Selectors, Misc
import * as actions from '../../actions';
import * as selectors from '../../selectors';

const className = 'main-container-end';
const el = (name, mod) => cn(className, name, mod);

const loaderWrap = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
	},
};

const End = ({ pushData, study, state, videosToUpload, t }) => {
	const [pose, setPose] = useState('start');
	const { audienceUuid } = useSelector((state) => state);
	const { redirectUrl } = study.settings;
	const [isWaitingForVideosToUpload, setIsWaitingForVideosToUpload] = useState(false);

	const handleFinish = async () => {
		setPose('completed');
		if (videosToUpload?.length) {
			console.log('waiting for videos to upload...');
			setIsWaitingForVideosToUpload(true);
			await Promise.allSettled(videosToUpload);
		}
		pushData(false, audienceUuid);
		document.body.classList.add('pull-to-refresh');
	};

	useEffect(() => {
		handleFinish();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pushData]);

	const retry = () => pushData(true, audienceUuid);

	if (state === 0) {
		return (
			<div>
				<Message title={t('gone-wrong')} image={imgUnknown} summary={t('try-again')} />
				<ButtonFixed label={t('retry')} onClick={retry} />
			</div>
		);
	}

	if (state === 1) {
		return (
			<div>
				<Message title={t('gone-wrong')} image={imgUnknown} summary={t('summary-placeholder')} />
			</div>
		);
	}

	if (state === true && !redirectUrl) {
		return <Message title={t('thank-you')} image={imgAbout} summary={t('thank-you-summary')} />;
	}

	return (
		<div className={el('loader')}>
			<motion.div animate={pose} variants={loaderWrap} initial="start">
				<Loader centered />
				{isWaitingForVideosToUpload && (
					<div className={el('welcome-container')}>
						<h1 className={el('welcome-title')}>Processing Your Video Response</h1>
						<p className={el('welcome-content')} style={{ marginBottom: 0 }}>
							Thank you for your response! Please do not close the browser window as some of your video
							responses are still being processed.
						</p>
					</div>
				)}
			</motion.div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	study: selectors.getStudy(state),
	state: selectors.getState(state),
});

const mapDispatchToProps = (dispatch) => ({
	pushData: (force, audienceUuid) => dispatch(actions.pushData(force, audienceUuid)),
});

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(End));
