// packages
import React, { cloneElement, forwardRef, isValidElement } from 'react';
import PropTypes from 'prop-types';

// hooks
import { useScrollContainer } from '../ScrollProvider';

/**
 * ScrollWrapper component allows class components to use the `useScrollContainer` hook.
 * @param {object} props - Component props
 * @param {React.Ref} forwardedRef - Optional forwarded reference to the scroll container
 */

const ScrollClassWrapper = forwardRef(({ children }, forwardedRef) => {
	const contextScrollContainerRef = useScrollContainer();

	return (
		<>
			{/* Clone children and pass ref dynamically */}
			{isValidElement(children) ? cloneElement(children, { ref: contextScrollContainerRef }) : children}
		</>
	);
});

ScrollClassWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

ScrollClassWrapper.displayName = 'ScrollClassWrapper';

export default ScrollClassWrapper;
