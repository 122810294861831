import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import cn from 'src/utilities/bem-cn';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
import AssetWithText from 'src/components/new/AssetWithText';
import Button from 'src/components/new/Button';
import ProgressBar from 'src/components/new/ProgressBar';
import { motion } from 'framer-motion';
import * as misc from 'src/utilities/misc';
import { getWpmData } from 'src/utilities/wpm';
import cards from '@upsiide/product-cards';
import Loader from 'src/components/elements/Loader';
import GlobalScrollIndicator from 'src/components/helpers/GlobalScrollIndicator';
import Question from '../../components/Question';
import ProductDataUtil from '../../../../utilities/product-data-util';
import RankedResetButton from '../../../../components/shared/RankedResetButton';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import './styles.scss';
import { getQuestionsWithLabels } from '../../../../utilities/misc';

const STUDIES_AFFECTED_WITH_INCORRECT_ALIGNMENT_BUG = [24559, 24592, 24875, 24893, 24905, 25104, 25308, 24196, 25312];

const showIncorrectAlignmentBugView = (studyId) => {
	if (!studyId || !STUDIES_AFFECTED_WITH_INCORRECT_ALIGNMENT_BUG?.length) return false;
	return STUDIES_AFFECTED_WITH_INCORRECT_ALIGNMENT_BUG.includes(studyId);
};

const className = 'monadic-split-container';

const el = (name, mod) => cn(className, name, mod);

const variants = {
	initial: {
		y: 50,
		opacity: 0,
		transition: {
			duration: 0.25,
		},
	},
	completed: {
		y: 0,
		opacity: 1,
		staggerChildren: 100,
		transition: {
			duration: 0.25,
		},
	},
};

const questionVariants = {
	initial: {
		y: 50,
		opacity: 0,
		transition: {
			duration: 0.25,
		},
	},
	completed: {
		y: 0,
		opacity: 1,
		staggerChildren: 100,
		transition: {
			duration: 0.25,
		},
	},
};

const MonadicSplit = ({
	t,
	study = {},
	setSectionAnswers,
	setNextSection,
	currentSection,
	jumpToQuestion,
	questionIndex,
	setQuestionIndex,
	allAnswers,
	swipeResults,
	focusEndTime,
	focusStartTime,
	clearInputFocusStartTime,
	isDistributedSplitProductsLoading,
	productIndex,
	setProductIndex,
	sendVideoToApi,
}) => {
	if (!currentSection) {
		return null;
	}

	if (isDistributedSplitProductsLoading) {
		return (
			<div className={el('loader-container')}>
				<Loader centered />
			</div>
		);
	}

	const stackCardRef = useRef(null);
	const elementRef = useRef(true);
	const splitsContainerRef = useRef(null);

	// * current section props
	const { statements, products, questions, settings } = currentSection;

	// * local state for managing the different steps of a monadic split section
	const [scrolled, setScrolled] = useState(false);
	const [showProduct, setShowProduct] = useState(true);
	// const [productIndex, setProductIndex] = useState(0);
	// const [questionIndex, setQuestionIndex] = useState(0);
	const [hasStatementBeenSeen, setHasStatementBeenSeen] = useState(false);
	const containerHeightBuffer = useRef(20);
	const [hasProductBeenSeen, setHasProductBeenSeen] = useState(false);
	const [questionValue, setQuestionValue] = useState(null); // * Used to set the value on the question object and enable the next button
	const [animationKey, setAnimationKey] = useState(0);
	const [questionAnimationKey, setQuestionAnimationKey] = useState(0);
	const [buttonEnabled, setButtonEnabled] = useState(false);
	const [containerHeight, setContainerHeight] = useState(window.innerHeight - containerHeightBuffer.current);
	const [heatmapOptional, setHeatmapOptional] = useState(false);
	const [resetRankedOptions, setResetRankedOptions] = useState(false);

	const scrollContainerClass = 'scroll-container';
	const isMobile = useIsMobile();

	// * Local state for managing the saving of question answers
	const [answers, setAnswers] = useState({});

	const [showScrollIndicator, setShowScrollIndicator] = useState({});

	const { isGuidedVideoQuestionInProgress } = useSelector((state) => state.main);

	const resetScrollContainerClasses = () => {
		setTimeout(() => {
			// Reset scroll container
			const scrollContainer = document.querySelector(`[class*='scroll-container']`);
			if (scrollContainer) {
				scrollContainer.classList.remove('scrolled-to-bottom');
				scrollContainer.classList.remove('remove-scroll-indicator');
				scrollContainer.scrollTo(0, 0);
			}
		}, 100);
	};
	// Filter through currentSection.questions and remove any questions without a label
	const questionsWithLabels = getQuestionsWithLabels({ questions });

	// If the list of questions with labels is empty proceed to the next section
	if (questionsWithLabels.length === 0) {
		setNextSection();
		return false;
	}

	const preQuestion = useRef(true);

	useEffect(() => {
		window.addEventListener('resize', updateContainerHeight);
		window.addEventListener('orientationchange', updateContainerHeight);
		resetScrollContainerClasses();
		if (splitsContainerRef && splitsContainerRef.current) {
			setShowScrollIndicator(splitsContainerRef.current.scrollHeight > splitsContainerRef.current.clientHeight);
		}
		return () => {
			window.removeEventListener('resize', updateContainerHeight);
			window.removeEventListener('orientationchange', updateContainerHeight);
		};
	}, []);

	useEffect(() => {
		if (!hasStatementBeenSeen) return;
		const buttonHeightBuffer = hasProductBeenSeen ? 10 : 90;
		containerHeightBuffer.current = buttonHeightBuffer;
		setContainerHeight(window.innerHeight - buttonHeightBuffer);
	}, [hasStatementBeenSeen, hasProductBeenSeen]);

	useEffect(() => {
		// Wait for render cycle to check client height
		setTimeout(() => {
			const isScrolled = elementRef && elementRef.current && elementRef.current.clientHeight > window.innerHeight;
			setScrolled(isScrolled);
		});

		const question = questionsWithLabels[questionIndex];
		const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
		setHeatmapOptional(false);
		if (question?.style === 'heatmap') {
			setShowProduct(false);
			if (isOptional && question.style === 'heatmap') {
				const { id: productId } = products[productIndex];
				const valKey = Object.keys(answers).find((k) => k.includes(`${question?.id}-${productId}`));
				const value = answers[valKey]?.value;
				const hasValidUserInputAnswer = value?.length >= 1;
				if (!hasValidUserInputAnswer) setHeatmapOptional(true);
			} else {
				setHeatmapOptional(false);
			}
		} else {
			setShowProduct(true);
		}
		if (!question) return;

		if (!isOptional) return;

		if (question?.style === 'ranked') {
			return;
		}

		setButtonEnabled(true);
	}, [questionsWithLabels, questionIndex]);

	useEffect(() => {
		resetScrollContainerClasses();

		setQuestionValue(null);
		setButtonEnabled(false);
		setQuestionIndex(0);
		setHasStatementBeenSeen(false);
		setHasProductBeenSeen(false);
	}, [productIndex]);

	// Reset product index on first mount
	useEffect(() => {
		if (productIndex !== 0) {
			setProductIndex(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateContainerHeight = () => {
		setContainerHeight(window.innerHeight - containerHeightBuffer.current);
	};

	useEffect(() => {
		// Do pre-logic before we render a question
		if (preQuestion.current) {
			preQuestion.current = false;
		}
	}, [
		answers,
		preQuestion,
		productIndex,
		products.length,
		questionIndex,
		questionsWithLabels.length,
		setNextSection,
		setQuestionIndex,
		setSectionAnswers,
	]);

	// * Effects
	// TODO: Will not really need this effect just here to log answers after the update in the console
	useEffect(() => {
		console.log('fx - answers: ', answers);
		const question = questionsWithLabels[questionIndex];
		const product = products[productIndex];

		if (question.style === 'guided-video-question') {
			const isSkip =
				answers?.[`${question.id}-${product.id}`]?.optionId === 'SKIP' &&
				answers?.[`${question.id}-${product.id}`]?.value === 'SKIP';
			if (isSkip) next();
		}
		if (question.style === 'ranked') {
			const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
			const settings = question?.settings;
			const topNSet = settings?.find((s) => s?.label === 'top-n')?.value === 'true';

			const topN = topNSet
				? Math.min(
						parseInt(settings?.find((s) => s?.label === 'top-n-limit')?.value) || 1,
						answers[`${question.id}-${product.id}`]?.availableOptions?.length || 1,
				  )
				: Math.min(answers[`${question.id}-${product.id}`]?.availableOptions?.length || 10, 10);

			const answerLength = answers[`${question.id}-${product.id}`]?.value?.length || 0;
			const isEnabled = isOptional ? answerLength === 0 || answerLength >= topN : answerLength >= topN;

			setButtonEnabled(isEnabled);
		}
	}, [answers]);

	useEffect(() => {
		const question = questionsWithLabels[questionIndex];
		if (!question) return;
		const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';

		if (!isOptional) return;

		const product = products[productIndex];
		const answerKey = `${question.id}-${product.id}`;
		const answer = answers[answerKey];
		if (isOptional && answer?.otherOptionId && answer?.otherValue?.length === 0) {
			setButtonEnabled(false);
		} else if (question?.style !== 'ranked') {
			setButtonEnabled(true);
		}
	}, [questionsWithLabels, questionIndex]);

	const next = () => {
		misc.resetKeyboardNavigationFocus();
		const product = products[productIndex];
		const question = questionsWithLabels[questionIndex];

		const answerKey = `${question.id}-${product.id}`;
		const answer = answers[answerKey];
		if (answer && answer.type === 'open-ended') {
			const wpmData = getWpmData({ answer, focusStartTime, focusEndTime });
			answers[answerKey] = {
				...answer,
				...wpmData,
			};
		}
		if (answer && question && question.style === 'heatmap') {
			const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
			if (isOptional && !(answer?.value?.length > 0)) {
				const updatedAnswer = {
					...answer,
					type: 'heatmap',
					skipped: true,
				};
				answers[answerKey] = updatedAnswer;
			}
		}
		clearInputFocusStartTime();

		// * When you click next mark that you have seen the statement to true
		if (!hasStatementBeenSeen) {
			setHasStatementBeenSeen(true);
			setAnimationKey(animationKey + 1);
		} else {
			// * When you click next mark that you have seen a product to true
			if (!hasProductBeenSeen) {
				setHasProductBeenSeen(true);
				setAnimationKey(animationKey + 1);
			} else {
				// * When you click next navigate to the next question
				const haveQuestionsBeenAsked = questionIndex + 1 === questionsWithLabels.length;
				if (haveQuestionsBeenAsked) {
					if (products[productIndex + 1] === undefined) {
						// * Store the section answers in global redux state
						setSectionAnswers(answers);

						// * You have seen all the products, time to leave!
						setNextSection();
						setQuestionIndex(0);
					} else {
						// * If you've asked all the questions for the product then go to the next product
						preQuestion.current = true;
						setQuestionValue(null);
						setButtonEnabled(false);
						setQuestionIndex(0);

						setProductIndex(productIndex + 1);
						setHasStatementBeenSeen(false);
						setHasProductBeenSeen(false);
					}
				} else {
					// * Otherwise iterate through all the questions
					preQuestion.current = true;
					setQuestionValue(null);
					setButtonEnabled(false);
					setQuestionIndex(questionIndex + 1);
					setQuestionAnimationKey(questionAnimationKey + 1);
				}
			}
		}
	};

	const renderIdeasNumber = ({ label = '', thumbnail = '', thumbnailLabel = '' } = {}) => (
		<ProgressBar
			activeItem={productIndex}
			totalItemsCount={currentSection.products.length}
			screenReaderLabel={`You are on idea split ${productIndex + 1} of ${currentSection.products.length}`}
			{...{ label, thumbnail }}
		/>
	);

	const renderStatement = () => {
		const statement = statements[0];
		let text = '';

		if (statement && statement.text) text = statement.text;

		if (text === '<p><br></p>' || text.replace(/ /g, '').toLowerCase() === '<p></p>') text = '';

		const asset = '';
		return (
			<>
				<div className={el('statement-container')}>
					<div className={el('content')}>
						{currentSection.products.length > 1 && (
							<div className={el('progress-container-container')}>{renderIdeasNumber()}</div>
						)}
						<AssetWithText
							summary={text && text.length > 0 ? text : t('monadic-split-statement')}
							image={asset}
							pose="completed"
						/>
						<div className={el('inline-button-container')}>
							<div className={el('button')}>
								<Button label={t('continue')} onClick={next} dataTestId="continue-button" />
							</div>
						</div>
					</div>
				</div>
				<GlobalScrollIndicator show={showScrollIndicator} />
			</>
		);
	};

	const renderProduct = () => {
		const ideaScreenLayout = settings.find((s) => s.label === 'idea_screen_layout').value || 'invalid_layout';
		const imageCropping = settings.find((s) => s.label === 'image_cropping') || 'fit';
		const imageSize = settings.find((s) => s.label === 'image_size') || 'medium';
		const stackCardData = ProductDataUtil.getStackProduct(
			products[productIndex],
			ideaScreenLayout,
			imageSize,
			imageCropping,
		);

		if (ideaScreenLayout === 'sideBySide') stackCardData.twoColumn = true;

		// * We're using the stack card because it is used across the dashboard
		// * and has already been modified to accommodate the 'idea_screen_layout' setting (e.g. text only, image only, no title, title top, title bottom, side by side)
		return (
			<div className={el('product-container')}>
				{currentSection.products.length > 1 && (
					<div className={el('product-card-indicator-container')}>{renderIdeasNumber()}</div>
				)}

				<cards.StackCard
					product={stackCardData}
					ref={stackCardRef}
					monadicSplitSection
					interactionsDisabled
					expandTheImageLabel={t('expand-the-image')}
				/>

				<div className="monadic-split-product-image-button-container">
					<Button label={t('continue')} onClick={next} dataTestId="continue-button" />
				</div>
			</div>
		);
	};

	const handleChange = (product, question, optionId, style, otherValue = '', otherOptionId = 0, noneOptionId) => {
		const { id: productId } = product;
		const { id: questionId, style: type } = question;
		const { id: sectionId } = currentSection;
		const value = optionId;

		const answer = {
			value, // * Any text that is manually input
			optionId, // * The actual id of the selected option
			type, // * single-select, multi-select, open-ended
			sectionId, // * Id of the current section
			otherValue,
			otherOptionId,
			productId, // * Id of the current product
			productOrder: productIndex, // * Order that the product was presented in
		};

		const answerKey = `${questionId}-${productId}`;
		const newAnswers = {
			...answers,
			[answerKey]: {
				...answer,
				productName: product.name,
				availableOptions: answers[answerKey]?.availableOptions || [],
			},
		};

		if (!value && type === 'open-ended') {
			delete newAnswers[answerKey];
		}

		setAnswers(newAnswers);

		let hasValidUserInputAnswer = false;

		if (question.style === 'heatmap') {
			hasValidUserInputAnswer = misc.checkForValidHeatmap(question, value);
			const isOptional = misc.getQuestionSetting(question, 'optional') === 'true';
			if (isOptional) {
				hasValidUserInputAnswer = value?.length >= 1;
				if (!hasValidUserInputAnswer) setHeatmapOptional(true);
			}
			if (hasValidUserInputAnswer) {
				setHeatmapOptional(false);
			}
		} else if (question.style === 'open-ended') {
			hasValidUserInputAnswer = misc.checkForValidOpenEnded(question, value);
		} else if (question.style === 'guided-video-question') {
			const newA = {
				...answers,
				[answerKey]: {
					...answers[answerKey],
					type: 'guided-video-question', // * optionId
					wpm: 20, // * WPM
					value: value || 'OK',
					productId,
				},
			};
			setAnswers(newA);
			next();
		} else {
			hasValidUserInputAnswer = misc.checkForValidInputs(
				question,
				newAnswers[`${questionId}-${productId}`].optionId,
				question.style,
				noneOptionId,
				otherValue,
			);
		}
		if (question.style !== 'ranked') {
			setButtonEnabled(hasValidUserInputAnswer);
		}
	};

	const handleReset = (question) => {
		const { id: productId } = products[productIndex];
		setAnswers({
			...answers,
			[`${question.id}-${productId}`]: {
				...answers[`${question.id}-${productId}`],
				value: [],
			},
		});
		const scrollContainer = document.querySelector(`[class*='${scrollContainerClass}']`);
		scrollContainer.scrollTo(0, 0, { behavior: 'smooth' });
		setResetRankedOptions(true);
		setTimeout(() => {
			setResetRankedOptions(false);
		}, 50);
	};

	const renderQuestions = () => {
		const ideaScreenLayout = settings.find((s) => s.label === 'idea_screen_layout').value || 'invalid_layout';
		const imageCropping = settings.find((s) => s.label === 'image_cropping') || 'fit';
		const imageSize = settings.find((s) => s.label === 'image_size') || 'medium';
		const commitmentCardData = ProductDataUtil.getCommitmentProduct(
			products,
			ideaScreenLayout,
			imageSize,
			imageCropping,
			['large', 'full', 'medium', 'thumbnail'],
		)[productIndex];

		if (ideaScreenLayout === 'sideBySide') {
			commitmentCardData.twoColumn = true;
			commitmentCardData.stackProduct.twoColumn = true;
		}

		const isGrid = questionsWithLabels[questionIndex]?.style === 'grid';
		const isRankedQuestion = questionsWithLabels?.[questionIndex]?.style === 'ranked';
		const isVideoGuidedQuestion = questionsWithLabels?.[questionIndex]?.style === 'guided-video-question';

		const { id: productId, fieldOneType, fieldOne, name: productName } = products[productIndex];

		const productImageSrc = fieldOneType === 'asset' ? fieldOne?.[0]?.url : '';

		// * We're using the commitment card because it is used across the dashboard
		// * and has already been modified to accommodate the 'idea_screen_layout' setting (e.g. text only, image only, no title, title top, title bottom, side by side)
		return (
			<div
				className={`${el('question-container')} ${
					showIncorrectAlignmentBugView(study.id) ? 'incorrect-alignment-bug' : ''
				}`}
			>
				<div className={`${el('questions')}`} ref={elementRef}>
					{(!isVideoGuidedQuestion || isGuidedVideoQuestionInProgress) &&
						currentSection.products.length > 1 && (
							<div className={el('product-card-indicator-container-video-question')}>
								{renderIdeasNumber({
									thumbnail: isVideoGuidedQuestion && productImageSrc ? productImageSrc : '',
									thumbnailLabel: productName,
									label: isVideoGuidedQuestion ? 'Idea' : '',
								})}
							</div>
						)}

					{!isVideoGuidedQuestion && showProduct && (
						<cards.CommitmentCard
							previewTheProductLabel={t('previewTheProductLabel')}
							nextImageLabel={t('nextImageLabel')}
							previousImageLabel={t('previousImageLabel')}
							productImageLabel={t('productImageLabel')}
							expandTheImageLabel={t('expand-the-image')}
							acceptLabel={t('acceptLabel')}
							closeImagePreviewLabel={t('closeImagePreviewLabel')}
							product={commitmentCardData}
							interactionsDisabled={ideaScreenLayout === 'imageOnly'}
							trapKeyboardNavigationFocus={ideaScreenLayout !== 'imageOnly'}
						/>
					)}

					<motion.div
						key={`question-animation-${questionAnimationKey}`}
						initial="initial"
						animate="completed"
						variants={questionVariants}
					>
						<div className={el('questions-container')}>
							<Question
								key={questionsWithLabels?.[questionIndex]?.id}
								language={study.language}
								question={questionsWithLabels?.[questionIndex]}
								value={!questionValue ? 0 : questionValue}
								onChange={(optionId, style, otherValue, otherOptionId, noneIndex) => {
									// * Managing steps withing the monadic split section
									setQuestionValue(optionId);
									// * Storing answers
									handleChange(
										products[productIndex],
										questionsWithLabels[questionIndex],
										optionId,
										style,
										otherValue,
										otherOptionId,
										noneIndex,
									);
								}}
								answers={answers}
								setAnswers={setAnswers}
								currentSection={currentSection}
								handleResults={next}
								productId={products[productIndex].id} // * Id of the current product
								productOrder={productIndex} // * Order that the product was presented in
								resetRankedOptions={resetRankedOptions}
								sendVideoToApi={sendVideoToApi}
							/>

							{!isGrid && !isVideoGuidedQuestion && (
								<div className={el('inline-button-container')}>
									<div className={el('button')}>
										<Button
											disabled={!buttonEnabled}
											label={t(heatmapOptional ? 'skip' : 'continue')}
											onClick={next}
											tabindex={!questionValue ? '-1' : 0}
											dataTestId="continue-button"
										/>
									</div>
								</div>
							)}

							{isRankedQuestion && isMobile && (
								<RankedResetButton
									handleReset={handleReset}
									disabled={
										answers[`${questionsWithLabels[questionIndex]?.id}-${productId}`]?.value
											?.length < 1
									}
									question={questionsWithLabels[questionIndex]}
								/>
							)}
						</div>
					</motion.div>
				</div>
			</div>
		);
	};

	const getMarkup = () => {
		if (!hasStatementBeenSeen) {
			return renderStatement();
		}
		if (!hasProductBeenSeen) {
			return renderProduct();
		}
		return renderQuestions();
	};

	return (
		<motion.div
			key={`animation-${animationKey}`}
			className={className}
			ref={splitsContainerRef}
			style={{
				height: `${containerHeight}px`,
				maxHeight: `${containerHeight}px`,
				minHeight: `${containerHeight}px`,
			}}
			initial="initial"
			animate="completed"
			variants={variants}
		>
			{getMarkup()}
		</motion.div>
	);
};

const mapStateToProps = (state) => ({
	study: selectors.getStudy(state),
	currentSection: selectors.getCurrentSection(state),
	questionIndex: selectors.getQuestionIndex(state),
	allAnswers: selectors.getAnswers(state),
	swipeResults: selectors.getResults(state),
	focusStartTime: selectors.getFocusStartTime(state),
	focusEndTime: selectors.getFocusEndTime(state),
	isDistributedSplitProductsLoading: selectors.getIsDistributedSplitProductsLoading(state),
	productIndex: selectors.getProductIndex(state),
});

const mapDispatchToProps = (dispatch) => ({
	setQuestionIndex: (index) => dispatch(actions.setQuestionIndex(index)),
	setNextSection: () => dispatch(actions.setNextSection()),
	setSectionAnswers: (answers) => dispatch(actions.setAnswers(answers)),
	jumpToQuestion: (questionId, sectionId) => dispatch(actions.jumpToQuestion(questionId, sectionId)),
	clearInputFocusStartTime: () => dispatch(actions.setInputFocusStartTime(null)),
	setProductIndex: (index) => dispatch(actions.setProductIndex(index)),
});

MonadicSplit.propTypes = {
	t: PropTypes.any,
	study: PropTypes.any,
	currentSection: PropTypes.any,
	onComplete: PropTypes.any,
	jumpToQuestion: PropTypes.func,
	setQuestionIndex: PropTypes.func,
	allAnswers: PropTypes.any,
	swipeResults: PropTypes.any,
	isDistributedSplitProductsLoading: PropTypes.bool,
	productIndex: PropTypes.number,
	setProductIndex: PropTypes.func,
};

export default withTranslation('main')(connect(mapStateToProps, mapDispatchToProps)(MonadicSplit));
