export default {
	'this-survey-does-not-exist': 'क्षमा करें, यह सर्वेक्षण मौजूद नहीं है।',
	'survey-closed': 'क्षमा करें, यह सर्वेक्षण अब बंद हो गया है।',
	'survey-no-products': 'इस सर्वेक्षण में कोई उत्पाद नहीं है।',
	'survey-no-products-summary': 'यदि आप इस सर्वेक्षण के स्वामी हैं, तो आगे बढ़ने के लिए डैशबोर्ड में उत्पाद बनाएं।',
	'survey-completed': 'आप यह सर्वेक्षण पहले ही पूरा कर चुके हैं।',
	'summary-placeholder':
		'यदि आपको लगता है कि यह एक ग़लती है, तो कृपया उस व्यक्ति से संपर्क करें जिसने आपको यह लिंक भेजा है।',
	'gone-wrong': 'हम्म, कुछ गडबड है।',
	'try-again': 'दुबारा प्रयत्न कीजिए या थोड़ी देर में वापस आईएं।',
	'thank-you': 'धन्यवाद',
	'thank-you-summary': 'सर्वेक्षण पूरा हो गया है, और आपके परिणाम दर्ज कर दिए गए हैं।',
	'thank-you-summary-redirect': 'आपको कुछ ही सेकंड में रीडायरेक्ट कर दिया जाएगा।',
	'page-not-found': 'कुछ गडबड है',
	'page-not-found-placeholder': 'उफ़, टूटी हुई लिंक',
	'tap-your-favorite': 'अपने पसंदीदा पर चेकमार्क को टैप कीजिए',
	previewTheProductLabel: 'उत्पाद का पूर्वावलोकन',
	nextImageLabel: 'अगली छवि',
	previousImageLabel: 'पिछली छवि',
	productImageLabel: 'उत्पाद की छवि',
	acceptLabel: 'स्वीकार करें',
	closeImagePreviewLabel: 'छवि पूर्वावलोकन बंद करें',
	or: 'अथवा',
	'submit-comment': 'टिप्पणी जमा करें',
	'reset-comments': 'टिप्पणियाँ रीसेट करें',
	'get-started-with-questions': 'हमें शुरुआत करने के लिए कुछ प्रश्न',
	continue: 'जारी रखें',
	'increase-ranking': '{{label}} के लिए रैंकिंग बढ़ाएं',
	'decrease-ranking': '{{label}} के लिए रैंकिंग कम करें',
	retry: 'दुबारा प्रयास कीजिए',
	'tell-us-how-you-feel-about-this-product': 'हमें बताएं कि आप इस उत्पाद के बारे में कैसा महसूस करते हैं',
	begin: 'आरंभ करें',
	'how-would-you-describe-this-product-to-a-friend': 'आप एक मित्र को इस उत्पाद का कैसे वर्णन करेंगे?',
	'type-your-response-here': 'अपनी प्रतिक्रिया यहाँ टाईप कीजिए',
	'sum-of-total-characters': ' {{TOTAL}} में से {{SUM}} अक्षर',
	edit: 'संपादित कीजिए',
	remove: 'हटा दें',
	'how-do-you-feel-about-this-part': 'इस भाग के बारे में आप कैसा महसूस करते हैं?',
	'tap-on-an-area-and-choose-an-emoji': 'किसी क्षेत्र पर टैप कीजिए और एक इमोजी चुनिए',
	'im-finished': 'मेरा पूरा हो गया',
	maintenance: 'रखरखाव',
	'maintenance-mode': 'Upsiide का अभी रखरखाव किया जा रहा है। कृपया कुछ समय बाद दोबारा देखें।',
	'please-specify': 'कृपया निर्दिष्ट कीजिए',
	'monadic-split-statement':
		'अगली स्क्रीन पर, हम आपको एक विचार दिखाएंगे। कृपया इसकी सावधानीपूर्वक समीक्षा कीजिए क्योंकि हमारे पास इसके बारे में प्रश्न होंगे।',
	disapproval: 'अस्वीकृति',
	anger: 'क्रोध',
	sad: 'दुखी',
	joy: 'हर्ष',
	love: 'प्रेम',
	trust: 'विश्वास',
	hope: 'आशा',
	proud: 'गर्व',
	amazed: 'चकित',
	surprised: 'विस्मित',
	guilt: 'अपराध बोध',
	curious: 'जिज्ञासु',
	jealous: 'ईर्ष्या',
	anticipation: 'प्रत्याशा',
	optimistic: 'आशावादी',
	anxious: 'चिंतित',
	fear: 'डर',
	despair: 'निराशा',
	disbelief: 'अविश्वास',
	cynicism: 'कुटिलता',
	regret: 'खेद',
	disgust: 'घृणा',
	annoyed: 'नाराज़',
	aggressive: 'आक्रामक',
	shocked: 'स्तंभित',
	choose: '{{amount}} चुनिए',
	'up-to': '{{amount}} तक चुनिए',
	unlimited: 'आप जितने चाहे उतने चुनिए',
	'single-select': 'एक का चयन',
	number: 'एक संख्या दर्ज कीजिए',
	'number-between-min-max': '{{min}} और {{max}} के बीच की कोई संख्या दर्ज कीजिए',
	'number-min': '{{min}} या उससे अधिक संख्या दर्ज कीजिए',
	'number-max': '{{max}} या उससे कम संख्या दर्ज कीजिए',
	'open-ended-pledge':
		'इस सर्वेक्षण में ऐसे प्रश्न हैं जिनका उत्तर आपको अपने शब्दों में देने के लिए कहा जाएगा। यह सुनिश्चित करने के लिए कि वे हमारी गुणवत्ता आवश्यकताओं को पूरा करते हैं, आपकी प्रतिक्रियाओं की स्वचालित रूप से जाँच की जाएगी। क्या आप आगे बढ़ने के लिए सहमत हैं? ',
	'open-ended-pledge-accept': 'हाँ, मैं सहमत हूँ',
	'open-ended-pledge-decline': 'नहीं, मैं सहमत नहीं हूँ',
	'rh-1-label': 'निम्नलिखित में से कौन सा खेल नहीं है?',
	'rh-1-o-1': 'बास्केटबॉल',
	'rh-1-o-2': 'टेनिस',
	'rh-1-o-3': 'बिस्कुट',
	'rh-1-o-4': 'हॉकी',
	'rh-2-label': '2+3 कितना है?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'निम्नलिखित में से कौन सा फल है?',
	'rh-3-o-1': 'दूध',
	'rh-3-o-2': 'चॉकलेट',
	'rh-3-o-3': 'अंडा',
	'rh-3-o-4': 'सेब',
	'open-end-max-length': 'क्षमा करें, दर्ज किया गया उत्तर बहुत लंबा है',
	'heatmap-click-image': `टिप्पणी जोड़ने के लिए प्रतिमा पर क्लिक करें`,
	'heatmap-click-image-no-comments': `प्रतिमा पर कहीं भी क्लिक करें`,
	'heatmap-click-image-product-card': `कार्ड जोड़ने के लिए प्रतिमा पर क्लिक करें`,
	'heatmap-click-image-product-card--no-comment': `कार्ड पर कहीं भी क्लिक करें`,
	'heatmap-type-comment': `एक टिप्पणी टाइप करें`,
	'heatmap-add-comment': 'एक टिप्पणी जोड़ने',
	cancel: `कैन्सल कर दो`,
	skip: 'छोडना',
	ranked: 'वें स्थान पर',
	top: 'शीर्ष',
	'tap-or-drag': 'रैंक करने के लिए टैप करें या खींचें और छोड़ें',
	unranked: 'बिना श्रेणी के',
	reset: 'रीसेट',
	'no-more-options': 'रैंक करने के लिए कोई और विकल्प नहीं',
	'rank-list-full': 'रैंक सूची पूरी हो गई है, अपनी पसंद में से किसी एक को अनरैंक करें',
	'expanded-image': 'विस्तृत छवि',
	'expand-the-image': 'छवि को विस्तारित करें',
	'type-here': 'यहाँ टाइप करें',
	'continue-survey': 'सर्वेक्षण जारी रखें',
	'place-a-point-to-comment': 'टिप्पणी करने के लिए एक बिंदु रखें',
};
