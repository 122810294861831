import * as actions from '../actions';

const INITIAL_STATE = {
	// Application state management data
	step: 'start',
	state: null, // null for no state, true for success, anything else for the error

	// Application content
	study: null,

	// Application collected data
	responseId: null,
	RID: null,
	results: {
		best: null,
		interests: [],
		commitments: [],
	},
	answers: {},
	removedAnswers: {},
	sentimentTags: [],
	sentimentText: [],
	textAIProductIds: [],

	// Question index for navigating question modules
	questionIndex: 0,

	// Modular step routing
	currentSectionIndex: 0,
	sections: [],
	distributedSplitProductsLoading: true,
	qualifiers: null,
	validateRespondentLoading: false,
	// Video guided questions
	audioOnly: false,
	isGuidedVideoQuestionInProgress: false,
	requiredVideoQuestions: [],
	optionalVideoQuestions: [],
	productIndex: 0,
	loadingTerminate: false,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		// TODO: Consider getting rid of SET_STEP altogether
		case actions.SET_STEP:
			return { ...state, step: action.payload.step };

		case actions.SET_CURRENT_SECTION_INDEX:
			return { ...state, currentSectionIndex: action.payload.currentSectionIndex };

		case actions.SET_SECTIONS:
			return { ...state, sections: action.payload.sections };

		case actions.SET_STATE:
			return { ...state, state: action.payload.state };

		case actions.SET_STUDY:
			return { ...state, study: action.payload.study };

		case actions.SET_RESPONSE_ID:
			return { ...state, responseId: action.payload.responseId };

		case actions.SET_RESPONSE_RID:
			return { ...state, RID: action.payload.RID };

		case actions.SET_RESULTS:
			// Merge results from multiple swipe sections
			return {
				...state,
				results: {
					interests: [...state.results.interests, ...action.payload.results.interests],
					commitments: [...state.results.commitments, ...action.payload.results.commitments],
					best: action.payload.results.best || state.results.best,
				},
			};

		case actions.SET_ANSWERS:
			// Merge answers from multiple question sections
			return { ...state, answers: { ...state.answers, ...action.payload.answers } };

		case actions.SET_REMOVED_ANSWERS:
			// Merge answers from multiple question sections
			return { ...state, removedAnswers: { ...state.removedAnswers, ...action.payload.answers } };

		case actions.SET_SENTIMENT_TAGS:
			return { ...state, sentimentTags: [...state.sentimentTags, ...action.payload.tags] };

		case actions.SET_SENTIMENT_TEXT:
			return { ...state, sentimentText: [...state.sentimentText, ...action.payload.description] };

		case actions.REPLACE_STATE:
			return action.payload.state;

		case actions.SET_QUESTION_INDEX:
			return { ...state, questionIndex: action.payload.questionIndex };

		case actions.SET_ASSET_PREVIEW_MODAL_URL:
			return { ...state, study: { ...state.study, assetPreviewModalUrl: action.payload.assetPreviewModalUrl } };

		case actions.SET_FOCUS_START_TIME:
			return { ...state, focusStartTime: action.payload.focusStartTime };

		case actions.SET_FOCUS_END_TIME:
			return { ...state, focusEndTime: action.payload.focusEndTime };

		case actions.SET_MAIN_STORE:
			return { ...action.payload.main };

		case actions.SET_DISTRIBUTED_SPLIT_PRODUCTS_LOADING:
			return { ...state, distributedSplitProductsLoading: action.payload.distributedSplitProductsLoading };

		case actions.SET_QUALIFIERS:
			return { ...state, qualifiers: action.payload.qualifiers };
		case actions.SET_VALIDATE_RESPONDENT_LOADING:
			return { ...state, validateRespondentLoading: action.payload.validateRespondentLoading };
		case actions.SET_AUDIO_ONLY:
			return { ...state, audioOnly: action.payload };

		case actions.SET_IS_GUIDED_VIDEO_QUESTION_IN_PROGRESS:
			return { ...state, isGuidedVideoQuestionInProgress: action.payload };

		case actions.SET_REQUIRED_VIDEO_QUESTIONS:
			return { ...state, requiredVideoQuestions: action.payload };

		case actions.SET_OPTIONAL_VIDEO_QUESTIONS:
			return { ...state, optionalVideoQuestions: action.payload };

		case actions.SET_PRODUCT_INDEX:
			return { ...state, productIndex: action.payload.productIndex };

		case actions.SET_LOADING_TERMINATE:
			return { ...state, loadingTerminate: action.payload };

		default:
			return state;
	}
};
