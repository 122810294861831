// packages
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
// assets
import NoMicIcon from 'public/images/mic-blocked.svg';
import IconWarning from 'public/images/icon-warning.svg';
import NoCameraAllowIcon from 'public/images/no-camera.svg';
import placeIcon from 'public/images/place.svg';
import handIcon from 'public/images/front_hand.svg';
import privacyIcon from 'public/images/privacy.svg';
import Loader from 'src/components/elements/Loader';
// utilities
import cn from 'src/utilities/bem-cn';
import api from 'src/utilities/api';
import { detectBrowser } from 'src/utilities/misc';
// actions
import * as actions from 'src/domains/main/actions';
// hooks
import useMediaPermissions from 'src/utilities/hooks/useMediaPermissions';
import useIsMobile from 'src/utilities/hooks/useIsMobile';
// components
import Button from 'src/components/new/Button';
import Preview from './Preview';
// selectors
import * as selectors from '../../../../../../selectors';
import ModalPermissionSettings from '../ModalPermissionSettings';
// styles
import './styles.scss';

const className = 'preview-checker';

const el = (name) => cn(className, name);

const PreviewChecker = ({ onProceed, question, setShowPreview, setAreYouSure }) => {
	// state
	const [loadingModerator, setLoadingModerator] = useState(false);

	const [cameraPermission, setCameraPermission] = useState(false);
	const [microphonePermission, setMicrophonePermission] = useState(false);
	const [permissionSettings, setPermissionSettings] = useState(false);
	const [canvasFrame, setCanvasFrame] = useState(null);

	const dispatch = useDispatch();

	const { refreshMediaPermissions, checkPermission } = useMediaPermissions();
	const isMobile = useIsMobile();

	const sections = useSelector((state) => selectors.getSections(state));
	const hasSelectedAudioOnly = useSelector((state) => state?.main?.audioOnly);
	const { responseId, study } = useSelector((state) => state.main);

	const audioStream = useRef(null);

	const deviceListRef = useRef([]);
	const audioContextRef = useRef(null);
	const initFocusRef = useRef(null);

	const allowAudioOnly = useMemo(() => {
		const videoQuestions = [];
		for (const section of sections) {
			videoQuestions.push(
				...(section?.questions?.filter(({ style }) => style === 'guided-video-question') || []),
			);
		}
		return !videoQuestions.some(
			({ settings }) => settings.find(({ label }) => label === 'allow_audio_only')?.value === 'false',
		);
	}, [sections]);

	const userPlatform = useMemo(() => {
		const ios = navigator.userAgent.match(/(iPhone|iPod)/i) ? 'ios' : '';
		const android = navigator.userAgent.match(/(android)/i) ? 'android' : '';
		// console.log('platform', ios || android || 'desktop', navigator.userAgent);
		if (!ios && !android) return 'desktop';
		return ios || android;
	}, []);

	// functions
	const checkAllowPermission = async () => {
		const browser = detectBrowser();
		if (browser === 'Firefox') {
			const video = await checkPermission('camera');
			const audio = await checkPermission('microphone');
			if (video) setCameraPermission(true);

			if (!video && !audio) return getPermission();
			if (!video && audio) {
				return getPermission(true, false);
			}
			if (video && audio) return getPermission();

			if (video && !audio) {
				return getPermission(false, true);
			}
		}

		const { isAudioPermissionGranted: microphone, isVideoPermissionGranted: camera } =
			await refreshMediaPermissions();

		if (!camera && !microphone) return getPermission();
		if (!camera && microphone) {
			return getPermission(true, false);
		}
		if (camera && microphone) return getPermission();

		if (camera && !microphone) {
			return getPermission(false, true);
		}
	};

	useEffect(() => {
		// Initial focus for screen readers
		initFocusRef.current?.focus();
	}, []);

	useEffect(() => {
		// Initialize AudioContext if it's not already created
		if (!audioContextRef.current) {
			audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
		}

		// Clean up the AudioContext when the component unmounts
		return () => {
			if (navigator?.allMediaStreams?.length) {
				navigator.allMediaStreams.forEach((stream) => {
					stream.getTracks().forEach((track) => {
						track.stop();
					});
				});
			}
			if (audioContextRef.current && typeof audioContextRef.current.close === 'function') {
				audioContextRef.current.close();
			}
		};
	}, []);

	const initialCheck = async () => {
		try {
			const videoPermission = await navigator.mediaDevices.getUserMedia({
				video: true,
			});
			saveMediaStream(videoPermission);
			setCameraPermission(videoPermission);
			checkAllowPermission();
		} catch (error) {
			console.log('Camera => ', error);
			setCameraPermission(false);
		}
		try {
			const audioPermission = await navigator.mediaDevices.getUserMedia({ audio: true });
			saveMediaStream(audioPermission);
			setMicrophonePermission(audioPermission);
			checkAllowPermission();
		} catch (error) {
			console.log('Mic => ', error);
			setMicrophonePermission(false);
		}
	};

	useEffect(() => {
		initialCheck();
	}, []);

	useEffect(() => {
		const innerScrollContainer = document.querySelector(`.scroll-container__inner`);
		if (innerScrollContainer) {
			innerScrollContainer.classList.add('scroll-container__inner--rtl');
		}
		return () => {
			innerScrollContainer.classList.remove('scroll-container__inner--rtl');
		};
	}, []);

	const setAudioOnly = useCallback((option) => dispatch(actions.setAudioOnly(option)), [dispatch]);

	const terminate = () =>
		dispatch(actions.setDisqualified([], study?.audienceUuid, `video-question-device-permission`));

	const continueToNextQuestion = async ({ microphone, camera }) => {
		// skip moderation for now
		// if (camera) {
		// 	if (!canvasFrame) await new Promise((r) => setTimeout(r, 2000));
		// 	await handleModerate();
		// }

		if (allowAudioOnly && microphone && !camera) {
			setAudioOnly(true);
			return onProceed();
		}

		if (microphone && camera) {
			setAudioOnly(false);
			return onProceed();
		}
	};

	const saveMediaStream = (mediaStream) => {
		if (!navigator?.allMediaStreams) {
			navigator.allMediaStreams = [];
		}
		navigator.allMediaStreams.push(mediaStream);
	};

	const getPermission = async (audio, edgeCase = false) => {
		try {
			const devices = await navigator.mediaDevices.enumerateDevices();
			const cameras = devices.filter((device) => device.kind === 'videoinput');
			if (edgeCase) {
				deviceListRef.current = cameras;
				const audioMedia = await navigator.mediaDevices.getUserMedia({ video: true });
				saveMediaStream(audioMedia);
				audioStream.current = audioMedia;
			}
			if (cameras.length && !audio) {
				deviceListRef.current = cameras;
				const audioMedia = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
				saveMediaStream(audioMedia);
				audioStream.current = audioMedia;
			} else {
				const audioMedia = await navigator.mediaDevices.getUserMedia({ audio: true });
				saveMediaStream(audioMedia);
				audioStream.current = audioMedia;
			}
			return true;
		} catch (error) {
			console.log(`No permission was given? => `, error);
			if (error.toString().includes('system')) {
				setShowPreview('denied');
			}
			return false;
		}
	};

	const renderHasSelectedAudioOnly = () => {
		if (!hasSelectedAudioOnly) return null;
		return (
			<div className={el('no-camera-container')}>
				<div className={el('no-camera-img-container')}>
					<img src={NoCameraAllowIcon} alt="No camera" className={el('no-camera-img')} />
				</div>
			</div>
		);
	};
	const renderNoPermission = () => {
		if (!microphonePermission || !cameraPermission)
			return (
				<div className={el('no-camera-container')}>
					<div className={el('no-camera-img-container')}>
						<img src={NoCameraAllowIcon} alt="No camera" className={el('no-camera-img')} />
					</div>

					<div className={el('no-access')}>
						{!microphonePermission ? (
							<div className={el('no-access-container')}>
								<img src={NoMicIcon} alt="No mic" className={el('no-access-icon')} />

								<span className={el('no-camera-text')}>No mic access</span>

								<img src={IconWarning} alt="No mic" className={el('no-access-warning')} />
							</div>
						) : null}

						{!cameraPermission ? (
							<div className={el('no-access-container')}>
								<img src={NoCameraAllowIcon} alt="No camera" className={el('no-access-icon')} />

								<span className={el('no-camera-text')}>No camera access</span>

								<img src={IconWarning} alt="No camera" className={el('no-access-warning')} />
							</div>
						) : null}
					</div>
				</div>
			);
		return null;
	};

	const renderSetupChecklist = () => (
		<div className={el('container')}>
			<h2 className={el('title')} tabIndex={-1} ref={initFocusRef}>
				Setup Checklist
			</h2>

			<div className={el('content')}>
				<span>Follow these guidelines for successful participation in video questions.</span>
			</div>

			<div className={el('list-rules')}>
				<span className={el('rule-item')}>
					<img className={el('rule-image')} src={placeIcon} alt="rule" />

					<span className={el('rule-text')}>
						Choose a quiet, well-lit space so you can be seen and heard clearly. Ensure you are visible at
						all times.
					</span>
				</span>

				<span className={el('rule-item')}>
					<img className={el('rule-image')} src={handIcon} alt="rule" />

					<span className={el('rule-text')}>
						Refrain from using profanity or offensive language. The use of inappropriate language will
						result in your disqualification from the survey.
					</span>
				</span>
				<span className={el('rule-item')}>
					<img className={el('rule-image')} src={privacyIcon} alt="rule" />

					<span className={el('rule-text')}>
						If you would like to know more about how we handle your data, please see our{' '}
						<a
							href="https://diginsights188902.app.privacycenter.cloud/#/?tab=PRIVACY_NOTICES"
							target="_blank"
							rel="noreferrer"
						>
							Privacy Policy.
						</a>
					</span>
				</span>
			</div>
			<div className={el('footer')}>
				{loadingModerator ? (
					<Loader />
				) : (
					<Button
						isBold
						label="Continue"
						onClick={async () => {
							if (loadingModerator) return;
							if (cameraPermission && microphonePermission)
								return continueToNextQuestion({
									microphone: microphonePermission,
									camera: cameraPermission,
								});

							if (allowAudioOnly && !cameraPermission && microphonePermission) {
								return continueToNextQuestion({
									microphone: microphonePermission,
									camera: cameraPermission,
								});
							}
							return setPermissionSettings(true);
						}}
					/>
				)}
				{!loadingModerator && (
					<Button
						isBold
						color="secondary"
						label="Skip video questions"
						onClick={() => {
							if (loadingModerator) return;
							return setAreYouSure(true);
						}}
					/>
				)}
			</div>
		</div>
	);

	const renderModalPermissionSettings = () => {
		if (permissionSettings) {
			return (
				<ModalPermissionSettings
					retry={() => {
						setPermissionSettings(false);
						return checkAllowPermission();
					}}
					onClose={() => {
						if (isMobile && userPlatform === 'ios') {
							window.location.reload();
						}
						setPermissionSettings(false);
					}}
					micPermission={!microphonePermission}
					cameraPermission={!cameraPermission}
				/>
			);
		}
		return null;
	};

	return (
		<div className={isMobile ? el('preview-container-mobile') : el('preview-container')}>
			{isMobile ? (
				<>
					<Preview
						renderHasSelectedAudioOnly={renderHasSelectedAudioOnly}
						renderNoPermission={renderNoPermission}
						renderAudioBars
						getCanvasFrame={(frame) => setCanvasFrame(frame)}
					/>
					{renderSetupChecklist()}
				</>
			) : (
				<>
					{renderSetupChecklist()}

					<Preview
						renderHasSelectedAudioOnly={renderHasSelectedAudioOnly}
						renderNoPermission={renderNoPermission}
						renderAudioBars
					/>
				</>
			)}

			{renderModalPermissionSettings()}
		</div>
	);
};

PreviewChecker.propTypes = {
	onProceed: PropTypes.func.isRequired,
	qualityTerminate: PropTypes.func.isRequired,
	setAreYouSure: PropTypes.func.isRequired,
	question: PropTypes.object.isRequired,
	setShowPreview: PropTypes.func.isRequired,
};

export default PreviewChecker;
