export default {
	'this-survey-does-not-exist': 'Esta pesquisa não existe.',
	'survey-closed': 'Esta pesquisa está encerrada.',
	'survey-no-products': 'Esta pesquisa não apresenta produtos.',
	'survey-no-products-summary': 'Caso seja o proprietário desta pesquisa, crie produtos no painel para prosseguir.',
	'survey-completed': 'Você já concluiu esta pesquisa. ',
	'summary-placeholder': 'Caso creia que este seja um erro, queira enviar um e-mail para quem te enviou este link. ',
	'gone-wrong': 'Uhmm, há algo errado. ',
	'try-again': 'Tente novamente ou volte mais tarde.',
	'thank-you': 'Obrigado(a)',
	'thank-you-summary': 'A pesquisa foi concluída e seus resultados foram enviados.',
	'thank-you-summary-redirect': 'Você será redirecionado em alguns segundos.',
	'page-not-found': 'Há algo errado',
	'page-not-found-placeholder': 'Quebra de link',
	'tap-your-favorite': 'Assinale o seu favorito',
	or: 'ou',
	'get-started-with-questions': 'Começaremos com algumas perguntas',
	continue: 'Prosseguir',
	retry: 'Tente novamente',
	'tell-us-how-you-feel-about-this-product': 'Diga-nos o que acha deste produto',
	begin: 'Iniciar',
	'how-would-you-describe-this-product-to-a-friend': 'Como descreveria este produto a um amigo?',
	'type-your-response-here': 'Digite sua resposta aqui...',
	'sum-of-total-characters': '${SOMA} do ${TOTAL} de caractéres',
	edit: 'Editar',
	remove: 'Remover',
	'how-do-you-feel-about-this-part': 'O que acha desta parte?',
	'tap-on-an-area-and-choose-an-emoji': 'Toque numa área e escolha um emoji',
	'im-finished': 'Terminei',
	maintenance: 'Manutenção',
	'maintenance-mode': 'O Upsiide está atualmente passando por manutenção. Queira retornar logo. ',
	'please-specify': 'Queira especificar',
	'monadic-split-statement':
		'No ecrã seguinte, vamos mostrar-lhe uma ideia. Analise‑a cuidadosamente, uma vez que iremos fazer‑lhe perguntas sobre ela.',
	disapproval: 'Desaprovação',
	anger: 'Irritação',
	sad: 'Tristeza',
	joy: 'Alegria',
	love: 'Amor',
	trust: 'Confiança',
	hope: 'Esperança',
	proud: 'Orgulho',
	amazed: 'Espantado',
	surprised: 'Surpreendido',
	guilt: 'Culpa',
	curious: 'Curioso',
	jealous: 'Ciumento',
	anticipation: 'Antecipação',
	optimistic: 'Otimista',
	anxious: 'Ansioso',
	fear: 'Medo',
	despair: 'Desespero',
	disbelief: 'Incredulidade',
	cynicism: 'Cinismo',
	regret: 'Mágoa',
	disgust: 'Repugnância',
	annoyed: 'Aborrecido',
	aggressive: 'Agressivo',
	shocked: 'Chocado(a)',
	choose: 'Escolha {{amount}}',
	'up-to': 'Escolha até {{amount}}',
	unlimited: 'Escolha tantos quanto quiser',
	'single-select': 'Escolha um',
	number: 'Introduza um número',
	'number-between-min-max': 'Introduza um número entre {{min}} e {{max}}',
	'number-min': 'Introduza um número {{min}} ou superior',
	'number-max': 'Introduza um número {{max}} ou inferior',
	'open-ended-pledge':
		'Esta pesquisa contém perguntas nas quais você será solicitado a responder com suas próprias palavras. Suas respostas serão verificadas automaticamente para garantir que atendam aos nossos requisitos de qualidade. Você concorda em continuar?',
	'open-ended-pledge-accept': 'Sim, eu concordo',
	'open-ended-pledge-decline': 'Não, eu discordo',
	'rh-1-label': 'Qual das seguintes opções não é um esporte?',
	'rh-1-o-1': 'Basquetebol',
	'rh-1-o-2': 'Tênis',
	'rh-1-o-3': 'Cookie',
	'rh-1-o-4': 'Hóquei',
	'rh-2-label': 'Quanto é 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Qual das seguintes opções é uma fruta?',
	'rh-3-o-1': 'Leite',
	'rh-3-o-2': 'Chocolate',
	'rh-3-o-3': 'Ovo',
	'rh-3-o-4': 'Maçã',
	previewTheProductLabel: 'Pré-visualizar o produto',
	nextImageLabel: 'Próxima Imagem',
	previousImageLabel: 'Imagem Anterior',
	productImageLabel: 'Imagem do Produto',
	acceptLabel: 'Aceitar',
	closeImagePreviewLabel: 'Fechar a Pré-visualização da Imagem',
	'submit-comment': 'Enviar comentário',
	'reset-comments': 'Redefinir comentários',
	'increase-ranking': 'Aumentar classificação para {{label}}',
	'decrease-ranking': 'Diminuir classificação para {{label}}',
	'open-end-max-length': 'Desculpe, a resposta digitada é muito longa',
	'heatmap-click-image': 'Clique na imagem para adicionar um comentário',
	'heatmap-click-image-no-comments': 'Clique em qualquer lugar na imagem',
	'heatmap-click-image-product-card': 'Clique no cartão para adicionar um comentário ',
	'heatmap-click-image-product-card--no-comment': 'Clique em qualquer lugar no cartão',
	'heatmap-type-comment': 'Escreva um comentário',
	'heatmap-add-comment': 'Adicione um comentário',
	cancel: 'Cancelar',
	skip: 'Pular',
	ranked: 'Classificado',
	top: 'Melhores',
	'tap-or-drag': 'Toque ou arraste e solte para classificar',
	unranked: 'Sem classificação',
	reset: 'Reiniciar',
	'no-more-options': 'Não há mais opções para classificar',
	'rank-list-full': 'A lista de classificação está cheia, desclassifique uma de suas escolhas',
	'expanded-image': 'Imagem expandida',
	'expand-the-image': 'Expanda a imagem',
	'type-here': 'Digite aqui',
	'continue-survey': 'Continuar a pesquisa',
	'place-a-point-to-comment': 'Coloque um ponto para comentar',
};
