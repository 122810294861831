import { useScrollContainer } from '../../components/helpers/ScrollProvider';

const useScroll = (customScrollContainerRef) => {
	const defaultScrollContainerRef = useScrollContainer();
	const scrollContainerRef = customScrollContainerRef || defaultScrollContainerRef;

	const scrollDown = () => {
		const container = scrollContainerRef.current;

		if (container) {
			// Calculate remaining scroll distance
			const scrollRemaining = container.scrollHeight - container.scrollTop - container.clientHeight;

			container.scrollBy({
				top: scrollRemaining,
				behavior: 'smooth',
			});
		}
	};

	return {
		scrollContainerRef,
		scrollDown,
	};
};

export default useScroll;
