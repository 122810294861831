import React, { createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

const ScrollContext = createContext(null);

export const ScrollProvider = ({ children }) => {
	const defaultScrollRef = useRef(null);

	return <ScrollContext.Provider value={defaultScrollRef}>{children}</ScrollContext.Provider>;
};

ScrollProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export const useScrollContainer = () => useContext(ScrollContext);
