export default {
	'this-survey-does-not-exist': 'Λυπούμαστε, αυτή η έρευνα δεν υπάρχει.',
	'survey-closed': 'Λυπούμαστε, αυτή η έρευνα έχει πλέον κλείσει.',
	'survey-no-products': 'Αυτή η έρευνα δεν έχει προϊόντα.',
	'survey-no-products-summary':
		'Αν είστε ο κάτοχος αυτής της έρευνας, δημιουργήστε προϊόντα στον πίνακα εργαλείων για να προχωρήσετε.',
	'survey-completed': 'Έχετε ολοκληρώσει ήδη αυτή την έρευνα.',
	'summary-placeholder':
		'Αν πιστεύετε ότι πρόκειται για λάθος, επικοινωνήστε με το άτομο που σας έστειλε τον σύνδεσμο.',
	'gone-wrong': 'Προέκυψε κάποιο σφάλμα.',
	'try-again': 'Δοκιμάστε ξανά ή επιστρέψτε αργότερα.',
	'thank-you': 'Ευχαριστούμε',
	'thank-you-summary': 'Η έρευνα ολοκληρώθηκε και τα αποτελέσματά σας υποβάλλονται.',
	'thank-you-summary-redirect': 'Θα ανακατευθυνθείτε σε λίγα δευτερόλεπτα.',
	'page-not-found': 'Προέκυψε κάποιο σφάλμα',
	'page-not-found-placeholder': 'Ο σύνδεσμος είναι κατεστραμμένος',
	'tap-your-favorite': 'Πατήστε το σημάδι επιλογής στο αγαπημένο σας',
	or: 'ή',
	previewTheProductLabel: 'Προεπισκόπηση του προϊόντος',
	nextImageLabel: 'Επόμενη Εικόνα',
	previousImageLabel: 'Προηγούμενη Εικόνα',
	productImageLabel: 'Εικόνα Προϊόντος',
	acceptLabel: 'Αποδοχή',
	closeImagePreviewLabel: 'Κλείσιμο Προεπισκόπησης Εικόνας',
	'submit-comment': 'Υποβολή σχολίου',
	'reset-comments': 'Επαναφορά σχολίων',
	'increase-ranking': 'Αύξηση κατάταξης για {{label}}',
	'decrease-ranking': 'Μείωση κατάταξης για {{label}}',
	'get-started-with-questions': 'Λίγες ερωτήσεις για να ξεκινήσουμε',
	continue: 'Συνέχεια',
	retry: 'Εκ νέου προσπάθεια',
	'tell-us-how-you-feel-about-this-product': 'Πείτε μας πώς νιώθετε για αυτό το προϊόν',
	begin: 'Έναρξη',
	'how-would-you-describe-this-product-to-a-friend': 'Πώς θα περιγράφατε αυτό το προϊόν σε έναν φίλο;',
	'type-your-response-here': 'Πληκτρολογήστε την απάντησή σας εδώâ€¦',
	'sum-of-total-characters': '{{SUM}} από {{TOTAL}} χαρακτήρες',
	edit: 'Επεξεργασία',
	remove: 'Αφαίρεση',
	'how-do-you-feel-about-this-part': 'Πώς νιώθετε για αυτό το θέμα;',
	'tap-on-an-area-and-choose-an-emoji': 'Πατήστε σε μια περιοχή και επιλέξτε ένα emoji',
	'im-finished': 'Έχω ολοκληρώσει',
	maintenance: 'Συντήρηση',
	'maintenance-mode': 'Αυτή τη στιγμή πραγματοποιούνται εργασίες συντήρησης στο Upsiide. Επιστρέψτε σύντομα.',
	'please-specify': 'Προσδιορίστε',
	'monadic-split-statement':
		'Στην επόμενη οθόνη, θα σας δείξουμε μια ιδέα. Ελέγξτε την προσεκτικά καθώς θα έχουμε σχετικές ερωτήσεις.',
	disapproval: 'Απόρριψη',
	anger: 'Θυμός',
	sad: 'Θλίψη',
	joy: 'Χαρά',
	love: 'Αγάπη',
	trust: 'Εμπιστοσύνη',
	hope: 'Ελπίδα',
	proud: 'Υπερηφάνεια',
	amazed: 'Θαυμασμός',
	surprised: 'Έκπληξη',
	guilt: 'Ενοχή',
	curious: 'Περιέργεια',
	jealous: 'Ζήλεια',
	anticipation: 'Προσμονή',
	optimistic: 'Αισιοδοξία',
	anxious: 'Νευρικότητα',
	fear: 'Φόβος',
	despair: 'Απόγνωση',
	disbelief: 'Δυσπιστία',
	cynicism: 'Κυνισμός',
	regret: 'Μεταμέλεια',
	disgust: 'Αποστροφή',
	annoyed: 'Ενόχληση',
	aggressive: 'Επιθετικότητα',
	shocked: 'Κατάπληξη',
	choose: 'Επιλέξτε {{amount}}',
	'up-to': 'Επιλέξτε έως {{amount}}',
	unlimited: 'Επιλέξτε όσα θέλετε',
	'single-select': 'Διάλεξε ένα',
	number: 'Εισαγάγετε έναν αριθμό',
	'number-between-min-max': 'Εισαγάγετε έναν αριθμό μεταξύ {{min}} και {{max}}',
	'number-min': 'Εισαγάγετε έναν αριθμό {{min}} ή μεγαλύτερο',
	'number-max': 'Εισαγάγετε έναν αριθμό {{max}} ή μικρότερο',
	'open-ended-pledge':
		'Αυτή η έρευνα περιέχει ερωτήσεις στις οποίες πρέπει να απαντήσετε με δικά σας λόγια. Θα ελέγξουμε αυτόματα τις απαντήσεις σας, για να βεβαιωθούμε ότι πληρούν τις απαιτήσεις ποιότητας. Συμφωνείτε να συνεχίσετε; ',
	'open-ended-pledge-accept': 'Ναι, συμφωνώ',
	'open-ended-pledge-decline': 'Όχι, διαφωνώ',
	'rh-1-label': 'Ποιο από τα παρακάτω δεν είναι άθλημα;',
	'rh-1-o-1': 'Μπάσκετ',
	'rh-1-o-2': 'Τένις',
	'rh-1-o-3': 'Μπισκότο',
	'rh-1-o-4': 'Χόκεϊ',
	'rh-2-label': 'Πόσο κάνει 2+3;',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Ποιο από τα παρακάτω είναι φρούτο;',
	'rh-3-o-1': 'Γάλα',
	'rh-3-o-2': 'Σοκολάτα',
	'rh-3-o-3': 'Αβγό',
	'rh-3-o-4': 'Μήλο',
	'open-end-max-length': 'Λυπούμαστε, η απάντηση που καταχωρίσατε είναι πολύ μεγάλη',
	'heatmap-click-image': `Κάντε κλικ στην εικόνα για να προσθέσετε ένα σχόλιο`,
	'heatmap-click-image-no-comments': `Κάντε κλικ οπουδήποτε στην εικόνα`,
	'heatmap-click-image-product-card': `Κάντε κλικ στην κάρτα για να προσθέσετε ένα σχόλιο `,
	'heatmap-click-image-product-card--no-comment': `Κάντε κλικ οπουδήποτε στην κάρτα`,
	'heatmap-type-comment': `Εισαγάγετε ένα σχόλιο`,
	'heatmap-add-comment': 'Προσθέστε ένα σχόλιο',
	cancel: `Άκυρο`,
	skip: 'Παραλείπω',
	ranked: 'Κατάταξη',
	top: 'Μπλουζα',
	'tap-or-drag': 'Πατήστε ή σύρετε και αποθέστε την κατάταξη',
	unranked: 'Χωρίς κατάταξη',
	reset: 'Επαναφορά',
	'no-more-options': 'Δεν υπάρχουν άλλες επιλογές για κατάταξη',
	'rank-list-full': 'Η λίστα κατάταξης είναι πλήρης, καταργήστε την κατάταξη μιας από τις επιλογές σας',
	'expanded-image': 'Επεκτεινόμενη εικόνα',
	'expand-the-image': 'Επεκτείνετε την εικόνα',
	'type-here': 'Πληκτρολογήστε εδώ',
	'continue-survey': 'Συνέχισε την έρευνα',
	'place-a-point-to-comment': 'Κάντε κλικ για να προσθέσετε ένα σχόλιο',
};
