export default {
	'this-survey-does-not-exist': 'Spiacenti, questo sondaggio non esiste.',
	'survey-closed': 'Spiacenti, questo sondaggio è terminato.',
	'survey-no-products': 'Questo sondaggio non ha prodotti.',
	'survey-no-products-summary':
		'Se lei è il proprietario di questo sondaggio, crei prodotti nella dashboard per procedere.',
	'survey-completed': 'Ha già completato il sondaggio.',
	'summary-placeholder': 'Se ritiene che si tratti di un errore, contatti la persona che le ha inviato questo link.',
	'gone-wrong': 'Ops, si è verificato un problema.',
	'try-again': 'Riprovi o torni più tardi.',
	'thank-you': 'Grazie',
	'thank-you-summary': 'Il sondaggio è concluso e i risultati sono stati inviati.',
	'thank-you-summary-redirect': 'Verrà reindirizzato in pochi secondi.',
	'page-not-found': 'Si è verificato un problema',
	'page-not-found-placeholder': 'Ops, link rotto',
	'tap-your-favorite': 'Tocchi il segno di spunta della sua opzione preferita',
	previewTheProductLabel: 'Anteprima del prodotto',
	nextImageLabel: 'Immagine successiva',
	previousImageLabel: 'Immagine precedente',
	productImageLabel: 'Immagine del prodotto',
	acceptLabel: 'Accetta',
	closeImagePreviewLabel: 'Chiudi anteprima immagine',
	or: 'oppure',
	'submit-comment': 'Invia commento',
	'reset-comments': 'Ripristina commenti',
	'get-started-with-questions': 'Alcune domande per iniziare',
	continue: 'Continua',
	'increase-ranking': 'Aumentare la classificazione per {{label}}',
	'decrease-ranking': 'Diminuire la classificazione per {{label}}',
	retry: 'Riprova',
	'tell-us-how-you-feel-about-this-product': 'Ci dica cosa ne pensa di questo prodotto',
	begin: 'Inizia',
	'how-would-you-describe-this-product-to-a-friend': 'Come descriverebbe questo prodotto a un amico?',
	'type-your-response-here': 'Digiti qui la sua risposta.',
	'sum-of-total-characters': '${SUM} caratteri su ${TOTAL}',
	edit: 'Modifica',
	remove: 'Elimina',
	'how-do-you-feel-about-this-part': 'Cosa ne pensa di questa parte?',
	'tap-on-an-area-and-choose-an-emoji': "Tocchi un'area e scelga un'emoji",
	'im-finished': 'Ho terminato',
	maintenance: 'Manutenzione',
	'maintenance-mode': 'Upsiide è attualmente in fase di manutenzione. La preghiamo di ricontrollare più tardi.',
	'please-specify': 'La preghiamo di specificare',
	'monadic-split-statement':
		"Nella schermata successiva, le mostreremo un'idea. La preghiamo di esaminarla attentamente, in quanto le porremo alcune domande al riguardo.",
	disapproval: 'Disapprovazione',
	anger: 'Rabbia',
	sad: 'Triste',
	joy: 'Gioia',
	love: 'Amore',
	trust: 'Fiducia',
	hope: 'Speranza',
	proud: 'Fiero/a',
	amazed: 'Stupefatto/a',
	surprised: 'Sorpreso/a',
	guilt: 'Colpevole',
	curious: 'Curioso/a',
	jealous: 'Geloso/a',
	anticipation: 'Attesa',
	optimistic: 'Ottimista',
	anxious: 'Ansioso/a',
	fear: 'Paura',
	despair: 'Disperazione',
	disbelief: 'Scetticismo',
	cynicism: 'Cinismo',
	regret: 'Rimpianto',
	disgust: 'Disgusto',
	annoyed: 'Infastidito/a',
	aggressive: 'Aggressivo/a',
	shocked: 'Scioccato/a',
	choose: 'Selezioni {{amount}}',
	'up-to': 'Selezioni fino a {{amount}}',
	unlimited: 'Selezioni tutte le risposte che desidera',
	'single-select': 'Selezioni uno',
	number: 'Inserisca un numero',
	'number-between-min-max': 'Inserisca un numero compreso tra {{min}} e {{max}}',
	'number-min': 'Inserisca un numero pari a {{min}} o superiore',
	'number-max': 'Inserisca un numero pari a {{max}} o inferiore',
	'open-ended-pledge':
		'Questo sondaggio contiene domande a cui le sarà chiesto di rispondere con parole sue. Le sue risposte saranno controllate automaticamente per essere certi soddisfino i nostri requisiti di qualità. Accetta di continuare? ',
	'open-ended-pledge-accept': 'Sì, accetto',
	'open-ended-pledge-decline': 'No, non accetto',
	'rh-1-label': 'Quale dei seguenti non è uno sport?',
	'rh-1-o-1': 'Pallacanestro',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Cookie',
	'rh-1-o-4': 'Hockey',
	'rh-2-label': 'Quanto fa 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Quale dei seguenti è un frutto?',
	'rh-3-o-1': 'Latte',
	'rh-3-o-2': 'Cioccolato',
	'rh-3-o-3': 'Uovo',
	'rh-3-o-4': 'Mela',
	'open-end-max-length': 'Siamo spiacenti, la risposta inserita è troppo lunga',
	'heatmap-click-image': `Fare clic sull’immagine per aggiungere un commento`,
	'heatmap-click-image-no-comments': `Fare clic su qualunque punto dell’immagine`,
	'heatmap-click-image-product-card': `Fare clic sulla carta per aggiungere un commento`,
	'heatmap-click-image-product-card--no-comment': `'Fare clic su qualunque punto della carta`,
	'heatmap-type-comment': `Inserire un commento'`,
	'heatmap-add-comment': `Aggiungi un commento`,
	cancel: `Cancellare`,
	skip: 'Saltare',
	ranked: 'Classificato',
	top: 'Più alto',
	'tap-or-drag': 'Tocca o trascina e rilascia per classificare',
	unranked: 'Non classificato',
	reset: 'Ripristina',
	'no-more-options': 'Non ci sono più opzioni per classificarsi',
	'rank-list-full': 'La classifica è piena, elimina la classifica da una delle tue scelte',
	'expanded-image': 'Immagine ingrandita',
	'expand-the-image': 'Espandi l’immagine',
	'type-here': 'Scrivi qui',
	'continue-survey': 'Continua il sondaggio',
	'place-a-point-to-comment': 'Posiziona un punto per commentare',
};
