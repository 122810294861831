export default {
	'this-survey-does-not-exist': 'Przepraszamy, ta ankieta nie istnieje.',
	'survey-closed': 'Przepraszamy, ankieta została zamknięta.',
	'survey-no-products': 'Ta ankieta nie zawiera żadnych produktów.',
	'survey-no-products-summary':
		'Jeśli jesteś właścicielem tej ankiety, utwórz produkty na pulpicie, aby kontynuować.',
	'survey-completed': 'Ankieta została już przez Ciebie wypełniona.',
	'summary-placeholder': 'Jeśli uważasz, że jest to błąd, skontaktuj się z osobą, która przesłała Ci to łącze.',
	'gone-wrong': 'Hmmm, coś jest nie tak.',
	'try-again': 'Spróbuj ponownie lub wróć później.',
	'thank-you': 'Dziękujemy',
	'thank-you-summary': 'Ankieta została ukończona, a Twoje wyniki zostały przesłane.',
	'thank-you-summary-redirect': 'Za kilka sekund nastąpi przekierowanie.',
	'page-not-found': 'Coś nie działa prawidłowo',
	'page-not-found-placeholder': 'Ups, uszkodzony link',
	'tap-your-favorite': 'Dotknij znacznika wyboru na swoim ulubionym',
	or: 'lub',
	'get-started-with-questions': 'Kilka pytań na początek',
	continue: 'Kontynuuj',
	retry: 'Ponów próbę',
	'tell-us-how-you-feel-about-this-product': 'Powiedz nam, co sądzisz o tym produkcie',
	begin: 'Rozpocznij',
	'how-would-you-describe-this-product-to-a-friend': 'Jak opisał(a)byś ten produkt znajomemu?',
	'type-your-response-here': 'Wpisz swoją odpowiedź tutaj...',
	'sum-of-total-characters': '${SUM} z ${TOTAL} znaków',
	edit: 'Edytuj',
	remove: 'Usuń',
	'how-do-you-feel-about-this-part': 'Co sądzisz o tej części?',
	'tap-on-an-area-and-choose-an-emoji': 'Dotknij obszaru i wybierz emoji',
	'im-finished': 'Skończyłem',
	maintenance: 'Konserwacja',
	'maintenance-mode': 'Upsiide jest obecnie w trakcie konserwacji. Sprawdź wkrótce.',
	'please-specify': 'Proszę określić',
	'monadic-split-statement':
		'Na następnym ekranie przedstawimy pewien pomysł. Zapoznaj się z nim uważnie, ponieważ będziemy o niego pytać.',
	disapproval: 'Dezaprobata',
	anger: 'Złość',
	sad: 'Smutek',
	joy: 'Radość',
	love: 'Miłość',
	trust: 'Zaufanie',
	hope: 'Nadzieja',
	proud: 'Duma',
	amazed: 'Zadziwienie',
	surprised: 'Zaskoczenie',
	guilt: 'Wina',
	curious: 'Ciekawość',
	jealous: 'Zazdrość',
	anticipation: 'Wyczekiwanie',
	optimistic: 'Optymizm',
	anxious: 'Niepokój',
	fear: 'Obawa',
	despair: 'Rozpacz',
	disbelief: 'Niedowierzanie',
	cynicism: 'Cynizm',
	regret: 'Żal',
	disgust: 'Obrzydzenie',
	annoyed: 'Podrażnienie',
	aggressive: 'Agresja',
	shocked: 'Szok',
	choose: 'Wybierz {{amount}}',
	'up-to': 'Wybierz do {{amount}}',
	unlimited: 'Wybierz dowolną liczbę opcji',
	'single-select': 'Wybierz jeden',
	number: 'Wpisz liczbę',
	'increase-ranking': 'Zwiększyć ranking dla {{label}}',
	'decrease-ranking': 'Zmniejszyć ranking dla {{label}}',
	'number-between-min-max': 'Wpisz liczbę w zakresie od {{min}} do {{max}}',
	'number-min': 'Wpisz liczbę równą lub większą niż {{min}}',
	'number-max': 'Wpisz liczbę równą lub mniejszą niż {{max}}',
	previewTheProductLabel: 'Podgląd produktu',
	nextImageLabel: 'Następne zdjęcie',
	previousImageLabel: 'Poprzednie zdjęcie',
	productImageLabel: 'Zdjęcie produktu',
	acceptLabel: 'Akceptuj',
	closeImagePreviewLabel: 'Zamknij podgląd zdjęcia',
	'submit-comment': 'Prześlij komentarz',
	'reset-comments': 'Resetuj komentarze',
	'open-ended-pledge':
		'Ankieta zawiera pytania, na które należy odpowiedzieć własnymi słowami. Twoje odpowiedzi będą automatycznie sprawdzane, aby zagwarantować, że spełniają nasze wymagania jakościowe. Czy wyrażasz zgodę na kontynuację? ',
	'open-ended-pledge-accept': 'Tak, zgadzam się',
	'open-ended-pledge-decline': 'Nie, nie zgadzam się',
	'rh-1-label': 'Która z poniższych opcji nie jest sportem?',
	'rh-1-o-1': 'Koszykówka',
	'rh-1-o-2': 'Tenis',
	'rh-1-o-3': 'Ciasteczko',
	'rh-1-o-4': 'Hokej',
	'rh-2-label': 'Ile jest 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Która z poniższych jest owocem?',
	'rh-3-o-1': 'Mleko',
	'rh-3-o-2': 'Czekolada',
	'rh-3-o-3': 'Jajko',
	'rh-3-o-4': 'Jabłko',
	'open-end-max-length': 'Przepraszamy, wprowadzona odpowiedź jest za długa',
	'heatmap-click-image': 'Kliknij grafikę, aby dodać komentarz',
	'heatmap-click-image-no-comments': 'Kliknij w dowolnym miejscu na grafice',
	'heatmap-click-image-product-card': 'Kliknij kartę, aby dodać komentarz',
	'heatmap-click-image-product-card--no-comment': 'Kliknij w dowolnym miejscu na karcie',
	'heatmap-type-comment': 'Wpisz komentarz',
	'heatmap-add-comment': 'Dodaj komentarz',
	cancel: 'Anuluj',
	skip: 'Pominąć',
	ranked: 'Rankingowe',
	top: 'Najwyższy',
	'tap-or-drag': 'Kliknij lub przeciągnij i upuść, aby wyświetlić ranking',
	unranked: 'Nierankingowe',
	reset: 'Resetowanie',
	'no-more-options': 'Nie ma już opcji rankingu',
	'rank-list-full': 'Lista rankingowa jest pełna, usuń rangę z jednej z wybranych opcji',
	'expanded-image': 'Rozszerzony obraz',
	'expand-the-image': 'Rozszerz obraz',
	'type-here': 'Wpisz tutaj',
	'continue-survey': 'Kontynuuj ankietę',
	'place-a-point-to-comment': 'Kliknij, aby dodać komentarz',
};
