export default {
	'this-survey-does-not-exist': 'Diese Umfrage existiert leider nicht.',
	'survey-closed': 'Leider ist diese Umfrage schon beendet.',
	'survey-no-products': 'Diese Umfrage enthält keine Produkte.',
	'survey-no-products-summary':
		'Um fortzufahren, wenn Sie der Eigentümer dieser Umfrage sind, stellen sie bitte die Produkte auf die Übersichtsseite.',
	'survey-completed': 'Sie haben an dieser Umfrage bereits teilgenommen.',
	'summary-placeholder':
		'Wenn sie glauben, dass dies ein Fehler ist, wenden sie sich bitte an die Person, die ihnen diesen Link geschickt hat.',
	'gone-wrong': 'Hmmm, irgendwas stimmt hier leider nicht.',
	'try-again': 'Bitte versuchen sie es erneut oder zu einem späteren Zeitpunkt.',
	'thank-you': 'Danke!',
	'thank-you-summary': 'Die Umfrage ist abgeschlossen. Ihre Ergebnisse werden übermittelt.',
	'thank-you-summary-redirect': 'Sie werden in wenigen Sekunden weitergeleitet.',
	'page-not-found': 'Irgendetwas stimmt hier nicht.',
	'page-not-found-placeholder': 'Hoppla, defekter Link.',
	'tap-your-favorite': 'Klicken sie auf ihren Favoriten.',
	'submit-comment': 'Kommentar absenden',
	'reset-comments': 'Kommentare zurücksetzen',
	or: 'oder',
	previewTheProductLabel: 'Produktvorschau',
	nextImageLabel: 'Nächstes Bild',
	previousImageLabel: 'Vorheriges Bild',
	productImageLabel: 'Produktbild',
	acceptLabel: 'Akzeptieren',
	closeImagePreviewLabel: 'Bildvorschau schließen',
	'increase-ranking': 'Erhöhen Sie das Ranking für {{label}}',
	'decrease-ranking': 'Verringern Sie das Ranking für {{label}}',
	'get-started-with-questions': 'Ein paar Fragen, die uns den Einstieg erleichtern sollen.',
	continue: 'weiter',
	retry: 'Bitte erneut versuchen.',
	'tell-us-how-you-feel-about-this-product': 'Sagen Sie uns, wie Sie sich zu diesem Produkt fühlen',
	begin: 'Start',
	'how-would-you-describe-this-product-to-a-friend': 'Wie würden Sie dieses Produkt einem Freund beschreiben?',
	'type-your-response-here': 'Geben Sie hier Ihre Antwort ein…',
	'sum-of-total-characters': '${SUM} von ${TOTAL} Zeichen',
	edit: 'Bearbeiten',
	remove: 'Entfernen',
	'how-do-you-feel-about-this-part': 'Wie denkst du über diesen Teil?',
	'tap-on-an-area-and-choose-an-emoji': 'Tippen Sie auf einen Bereich und wählen Sie ein Emoji',
	'im-finished': 'Ich bin fertig',
	maintenance: 'Maintenance',
	'maintenance-mode': 'Upsiide is currently undergoing maintenance. Please check back soon.',
	'please-specify': 'Bitte angeben',
	'monadic-split-statement':
		'Auf dem nächsten Bildschirm zeigen wir Ihnen eine Idee. Bitte lesen Sie sie sorgfältig durch, da wir Fragen dazu haben werden.',
	disapproval: 'Missbilligung',
	anger: 'Wut',
	sad: 'Traurig',
	joy: 'Freude',
	love: 'Liebe',
	trust: 'Vertrauen',
	hope: 'Hoffnung',
	proud: 'Stolz',
	amazed: 'Erstaunt',
	surprised: 'Überrascht',
	guilt: 'Schuldgefühl',
	curious: 'Neugierig',
	jealous: 'Eifersüchtig',
	anticipation: 'Vorfreude',
	optimistic: 'Optimistisch',
	anxious: 'Nervös',
	fear: 'Furcht',
	despair: 'Verzweiflung',
	disbelief: 'Unglauben',
	cynicism: 'Zynismus',
	regret: 'Bedauern',
	disgust: 'Ekel',
	annoyed: 'Verärgert',
	aggressive: 'Aggressiv',
	shocked: 'Geschockt',
	choose: 'Bitte wählen Sie {{amount}}',
	'up-to': 'Bitte wählen Sie bis zu {{amount}}',
	unlimited: 'Bitte beliebig viele auswählen',
	'single-select': 'Wähle ein',
	number: 'Bitte eine Zahl eingeben',
	'number-between-min-max': 'Bitte eine Zahl zwischen {{min}} und {{max}} eingeben',
	'number-min': 'Bitte einen Wert von mindestens {{min}} eingeben',
	'number-max': 'Bitte einen Wert von höchstens {{max}} eingeben',
	'open-ended-pledge':
		'Diese Umfrage enthält Fragen, die Sie mit Ihren eigenen Worten beantworten sollen. Ihre Antworten werden automatisch überprüft, um sicherzustellen, dass sie unseren Qualitätsanforderungen entsprechen. Sind Sie damit einverstanden, fortzufahren? ',
	'open-ended-pledge-accept': 'Ja, ich bin damit einverstanden.',
	'open-ended-pledge-decline': 'Nein, ich bin damit nicht einverstanden.',
	'rh-1-label': 'Bei welchen der folgenden Angaben handelt es sich nicht um einen Sport?',
	'rh-1-o-1': 'Basketball',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Keks',
	'rh-1-o-4': 'Hockey',
	'rh-2-label': 'Was ist 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Bei welchen der folgenden Angaben handelt es sich um eine Frucht?',
	'rh-3-o-1': 'Milch',
	'rh-3-o-2': 'Schokolade',
	'rh-3-o-3': 'Ei',
	'rh-3-o-4': 'Apfel',
	'open-end-max-length': 'Entschuldigung, die eingegebene Antwort ist zu lang',
	'heatmap-click-image': `Bitte klicken Sie auf das Bild, um einen Kommentar einzugeben`,
	'heatmap-click-image-no-comments': `Bitte klicken Sie irgendwo auf das Bild`,
	'heatmap-click-image-product-card': `Bitte klicken Sie auf die Karte, um einen Kommentar einzugeben `,
	'heatmap-click-image-product-card--no-comment': `Bitte klicken Sie irgendwo auf die Karte`,
	'heatmap-type-comment': `Bitte geben Sie einen Kommentar ein`,
	'heatmap-add-comment': 'Fügen Sie einen Kommentar hinzu',
	cancel: `Rückgängig',`,
	skip: 'Überspringen',
	ranked: 'Rangliste',
	top: 'Spitze',
	'tap-or-drag': 'Tippen oder ziehen und ablegen, um eine Rangfolge festzulegen',
	unranked: 'Ohne Rang',
	reset: 'Zurücksetzen',
	'no-more-options': 'Keine weiteren Optionen zum Ranking',
	'rank-list-full': 'Die Rangliste ist voll. Heben Sie den Rang einer Ihrer Optionen auf',
	'expanded-image': 'Erweitertes Bild',
	'expand-the-image': 'Bild erweitern',
	'type-here': 'Geben Sie hier ein',
	'continue-survey': 'Umfrage fortsetzen',
	'place-a-point-to-comment': 'Klicken Sie, um einen Kommentar hinzuzufügen',
};
