export default {
	'this-survey-does-not-exist': 'Pasensya na, pero walang ganitong survey.',
	'survey-closed': 'Pasensya na, pero tapos na ang survey na ito.',
	'survey-no-products': 'Walang mga produkto ang survey na ito.',
	'survey-no-products-summary':
		'Kung ikaw ang may-ari ng survey na ito, gumawa ng mga produkto sa dashboard para makapagpatuloy.',
	'survey-completed': 'Nakumpleto mo na ang survey na ito.',
	'summary-placeholder': 'Kung naniniwala kang hindi ito tama, kausapin ang tao na nagpadala sa iyo ng link na ito.',
	'gone-wrong': 'Hmmm, may maling nangyari.',
	'try-again': 'Subukan ulit o bumalik na lang maya-maya.',
	'thank-you': 'Salamat',
	'thank-you-summary': 'Kumpleto ang survey, at naisumite ang iyong mga resulta.',
	'thank-you-summary-redirect': 'Ire-redirect ka sa loob ng ilang segundo.',
	'page-not-found': 'May maling nangyari',
	previewTheProductLabel: 'Silipin ang Produkto',
	nextImageLabel: 'Susunod na Larawan',
	previousImageLabel: 'Nakaraang Larawan',
	productImageLabel: 'Larawan ng Produkto',
	acceptLabel: 'Tanggapin',
	closeImagePreviewLabel: 'Isara ang Preview ng Larawan',
	'page-not-found-placeholder': 'Oops, sira ang link',
	'tap-your-favorite': 'I-tap ang checkmark sa iyong paborito',
	or: 'o',
	'submit-comment': 'Isumite ang komento',
	'reset-comments': 'I-reset ang mga komento',
	'increase-ranking': 'Taasan ang ranking para sa {{label}}',
	'decrease-ranking': 'Bawasan ang ranking para sa {{label}}',
	'get-started-with-questions': 'Mga ilang tanong para makapagsimula tayo',
	continue: 'Magpatuloy',
	retry: 'Subukan ulit',
	'tell-us-how-you-feel-about-this-product': 'Sabihin sa amin kung ano ang palagay mo tungkol sa produktong ito',
	begin: 'Magsimula',
	'how-would-you-describe-this-product-to-a-friend': 'Paano mo ilalarawan ang produktong ito sa isang kaibigan?',
	'type-your-response-here': 'I-type ang iyong sagot dito…',
	'sum-of-total-characters': '${SUM} ng ${TOTAL} mga character',
	edit: 'I-edit',
	remove: 'Alisin',
	'how-do-you-feel-about-this-part': 'Ano ang palagay mo sa bahaging ito?',
	'tap-on-an-area-and-choose-an-emoji': 'Mag-tap sa isang lugar at pumili ng emoji',
	'im-finished': 'Tapos na ako',
	maintenance: 'Maintenance',
	'maintenance-mode':
		'Kasalukuyang sumasailalim ng maintenance ang Upsiide. Pakibalikan na lang ulit sa ibang panahon.',
	'please-specify': 'Pakitukoy',
	'monadic-split-statement':
		'Sa susunod na screen, magpapakita kami sa iyo ng ideya. Pakisuri ito nang mabuti dahil mayroon kaming mga tanong para sa iyo tungkol dito.',
	disapproval: 'Hindi nag-aapruba',
	anger: 'Galit',
	sad: 'Malungkot',
	joy: 'Masaya',
	love: 'Nagmamahal',
	trust: 'Nagtitiwala',
	hope: 'May pag-asa',
	proud: 'May ipinagmamalaki',
	amazed: 'Namamangha',
	surprised: 'Nabigla',
	guilt: 'May sala',
	curious: 'Mausisa',
	jealous: 'Naiingit',
	anticipation: 'Nananabik',
	optimistic: 'Optimista',
	anxious: 'Nababalisa',
	fear: 'Takot',
	despair: 'Nawalan ng pag-asa',
	disbelief: 'Hindi makapaniwala',
	cynicism: 'May pagka-uyam',
	regret: 'Nagsisisi',
	disgust: 'May pagkasuklam',
	annoyed: 'Naiinis',
	aggressive: 'Agresibo',
	shocked: 'Nabigla',
	choose: 'Pumili {{amount}}',
	'up-to': 'Pumili nang hanggang sa {{amount}}',
	unlimited: 'Pumili nang hanggang sa pinakamaraming gusto mo',
	'single-select': 'Pumili ng isa',
	number: 'Magbigay ng isang numero',
	'number-between-min-max': 'Magbigay ng isang numero sa pagitan ng {{min}} at ‎{{max}}',
	'number-min': 'Magbigay ng isang numero mula {{min}} pataas',
	'number-max': 'Wpisz liczbę równą lub mniejszą niż {{max}}',
	'open-ended-pledge':
		'May mga tanong sa survey na ito kung saan hihilingin sa iyo na sumagot ka gamit ang sarili mong pananalita. Awtomatikong susuriin ang iyong mga sagot para matiyak na natutugunan ng mga ito ang mga kinakailangan namin para sa kalidad. Pumapayag ka bang magpatuloy? ',
	'open-ended-pledge-accept': 'Oo, pumapayag ako',
	'open-ended-pledge-decline': 'Hindi, hindi ako pumapayag',
	'rh-1-label': 'Alin sa mga sumusunod ang hindi isang sport?',
	'rh-1-o-1': 'Basketball',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Cookie',
	'rh-1-o-4': 'Hockey',
	'rh-2-label': 'Ano ang 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Alin sa sumusunod ang isang prutas?',
	'rh-3-o-1': 'Gatas',
	'rh-3-o-2': 'Tsokolate',
	'rh-3-o-3': 'Itlog',
	'rh-3-o-4': 'Mansanas',
	'open-end-max-length': 'Paumanhin, masyadong mahaba ang inilagay na sagot',
	'heatmap-click-image': 'Mag-click sa larawan para makapagdagdag ng nais sabihin',
	'heatmap-click-image-no-comments': 'Mag-click kahit saan sa larawan',
	'heatmap-click-image-product-card': 'Mag-click sa card para makapagdagdag ng nais sabihin ',
	'heatmap-click-image-product-card--no-comment': 'Mag-click kahit saan sa card',
	'heatmap-type-comment': 'Mag-type ng sasabihin',
	'heatmap-add-comment': 'Magdagdag ng komento',
	cancel: 'Kanselahin',
	skip: 'Laktawan',
	ranked: 'Niraranggo',
	top: 'Nangungunang',
	'tap-or-drag': 'I-tap o i-drag at i-drop para mag-rank',
	unranked: 'Walang ranggo',
	reset: 'I-reset',
	'no-more-options': 'Wala nang mga pagpipilian sa ranggo',
	'rank-list-full': 'Puno na ang listahan ng ranggo, alisin sa ranggo ang isa sa iyong mga pagpipilian',
	'expanded-image': 'Pinalawak na larawan',
	'expand-the-image': 'Pinalawak ang larawan',
	'type-here': 'Mag-type dito',
	'continue-survey': 'Magpatuloy sa survey',
	'place-a-point-to-comment': 'Maglagay ng punto para mag-komento',
};
