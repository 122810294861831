export default {
	'this-survey-does-not-exist': 'Samahani, utafiti huu haupo.',
	'survey-closed': 'Samahani, utafiti huu sasa umefungwa.',
	'survey-no-products': 'Utafiti huu hauna bidhaa.',
	'survey-no-products-summary': 'Ikiwa wewe ndiwe mwenye utafiti huu, unda bidhaa kwenye dashibodi ili kuendelea.',
	'survey-completed': 'Tayari umekamilisha utafiti huu.',
	'summary-placeholder': 'Ikiwa unaamini hili ni kosa, tafadhali wasiliana na mtu uliyemtumia kiunganishi hiki.',
	'gone-wrong': 'Hmmm, kuna hitilafu fulani.',
	'try-again': 'Jaribu tena au urudi baadaye.',
	'thank-you': 'Asante',
	'thank-you-summary': 'Utafiti umekamilika na matokeo yako yamewasilishwa.',
	'thank-you-summary-redirect': 'Utaelekezwa kwingineko baada ya sekunde chache.',
	'page-not-found': 'Kuna hitilafu fulani',
	'page-not-found-placeholder': 'Samahani, kiunganishi kimekatizwa',
	'tap-your-favorite': 'Bofya alama ya tiki kwenye kipendwa chako',
	or: 'au',
	'get-started-with-questions': 'Maswali kadhaa ya kuanzia',
	continue: 'Endelea',
	retry: 'Jaribu tena',
	'tell-us-how-you-feel-about-this-product': 'Tuambie maoni yako kuhusu bidhaa hii',
	begin: 'Anza',
	'how-would-you-describe-this-product-to-a-friend': 'Je, utaielezea vipi bidhaa hii kwa rafiki yako?',
	'type-your-response-here': 'Andika jibu lako hapa',
	'sum-of-total-characters': '${SUM} kati ya herufi${TOTAL}',
	edit: 'Hariri',
	remove: 'Ondoa',
	'how-do-you-feel-about-this-part': 'Una maoni gani kuhusu sehemu hii?',
	'tap-on-an-area-and-choose-an-emoji': 'Bofya eneo fulani kisha uchague emoji',
	'im-finished': 'Nimemaliza',
	maintenance: 'Matengenezo',
	'maintenance-mode': 'Upsiide inatengenezwa kwa sasa. Tafadhali rudi uangalie hivi karibuni.',
	'please-specify': 'Tafadhali bainisha',
	'monadic-split-statement':
		'Kwenye skrini inayofuata, tutakuonyesha wazo fulani. Tafadhali litathmini kwa uangalifu kwa sababu tutauliza maswali kulihusu.',
	disapproval: 'Kukataa',
	anger: 'Hasira',
	sad: 'Huzuni',
	joy: 'Furaha',
	love: 'Upendo',
	trust: 'Imani',
	hope: 'Tumaini',
	proud: 'Fahari',
	amazed: 'Kustaajabu',
	surprised: 'Mshangao',
	guilt: 'Hatia',
	curious: 'Dadisi',
	jealous: 'Wivu',
	anticipation: 'Matazamio',
	optimistic: 'Matumaini mazuri',
	anxious: 'Wasiwasi',
	fear: 'Woga',
	despair: 'Kufa moyo',
	disbelief: 'Kutoamini',
	cynicism: 'Ubeuzi',
	regret: 'Majuto',
	disgust: 'Karaha',
	annoyed: 'Kuudhika',
	aggressive: 'Uchokozi',
	shocked: 'Kushtuka',
	choose: 'Chagua {{amount}}',
	'up-to': 'Chagua hadi {{amount}}',
	unlimited: 'Chagua nyingi kadiri unavyopenda',
	'single-select': 'Chagua moja',
	number: 'Weka nambari',
	'number-between-min-max': 'Weka nambari kati ya {{min}} na {{max}}',
	'number-min': 'Weka nambari {{min}} au kubwa kuliko hiyo',
	'number-max': 'Weka nambari {{max}} au ndogo kuliko hiyo',
	previewTheProductLabel: 'Onyesha kwa kina bidhaa',
	nextImageLabel: 'Picha inayofuata',
	previousImageLabel: 'Picha iliyotangulia',
	productImageLabel: 'Picha ya bidhaa',
	acceptLabel: 'Kubali',
	closeImagePreviewLabel: 'Funga onyesho la picha',
	'submit-comment': 'Wasilisha maoni',
	'reset-comments': 'Weka upya maoni',
	'increase-ranking': 'Ongeza cheo kwa {{label}}',
	'decrease-ranking': 'Punguza cheo kwa {{label}}',
	'open-ended-pledge':
		'Utafiti huu una maswali ambayo utaombwa ujibu kwa maneno yako mwenyewe. Majibu yako yatakaguliwa kiotomatiki ili kuhakikisha kwamba yanatimiza matakwa yetu ya ubora. Je, unakubali kuendelea? ',
	'open-ended-pledge-accept': 'Ndiyo, ninakubali',
	'open-ended-pledge-decline': 'Hapana, sikubali',
	'rh-1-label': 'Ni gani kati ya vitu vifuatavyo si spoti?',
	'rh-1-o-1': 'Basketboli',
	'rh-1-o-2': 'Tenisi',
	'rh-1-o-3': 'Kuki',
	'rh-1-o-4': 'Hoki',
	'rh-2-label': 'Je, 2+3 ni ngapi?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Kati ya vitu vifuatavyo, ni gani ni tunda?',
	'rh-3-o-1': 'Maziwa',
	'rh-3-o-2': 'Chokoleti',
	'rh-3-o-3': 'Yai',
	'rh-3-o-4': 'Tufaha',
	'open-end-max-length': 'Samahani, jibu lililowekwa ni refu sana',
	'heatmap-click-image': 'Bofya kwenye picha ili uongeze maoni',
	'heatmap-click-image-no-comments': 'Bofya popote kwenye picha',
	'heatmap-click-image-product-card': 'Bofya kwenye kadi ili uongeze maoni ',
	'heatmap-click-image-product-card--no-comment': 'Bofya popote kwenye kadi',
	'heatmap-type-comment': 'Andindika maoni',
	'heatmap-add-comment': 'Ongeza maoni',
	cancel: 'Ghairi',
	skip: 'Ruka',
	ranked: 'Imeorodheshwa',
	top: 'Bora',
	'tap-or-drag': 'Gusa au buruta na udondoshe ili kuweka cheo',
	unranked: 'Haijateuliwa',
	reset: 'Weka upya',
	'no-more-options': 'Hakuna chaguo zaidi za kuorodhesha',
	'rank-list-full': 'Orodha ya vyeo imejaa, toa cheo mojawapo ya chaguo zako',
	'expanded-image': 'Picha iliyopanuliwa',
	'expand-the-image': 'Panua picha',
	'type-here': 'Andika hapa',
	'continue-survey': 'Endelea na utafiti',
	'place-a-point-to-comment': 'Bofya ili uongeze maoni',
};
