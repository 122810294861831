export default {
	'this-survey-does-not-exist': 'מצטערים, סקר זה אינו קיים.',
	'survey-closed': 'מצטערים, סקר זה סגור כעת.',
	'survey-no-products': 'בסקר זה אין מוצרים.',
	'survey-no-products-summary': 'אם אתה הבעלים של סקר זה, עליך ליצור מוצרים בלוח מחוונים כדי להמשיך.',
	'survey-completed': 'כבר השלמת סקר זה.',
	'summary-placeholder': 'אם לדעתך זו טעות, נא לפנות אל האדם ששלח לך את הקישור.',
	'gone-wrong': 'הממם, משהו לא בסדר.',
	'try-again': 'נסה שוב או חזור אחר כך.',
	'thank-you': 'תודה לך',
	'thank-you-summary': 'הסקר הושלם, ותוצאותיך הוגשו.',
	'thank-you-summary-redirect': 'תועבר תוך מספר שניות.',
	'page-not-found': 'משהו לא בסדר',
	'page-not-found-placeholder': 'אופס, קישור לא עובד',
	'tap-your-favorite': 'הקש אות סימון על המוצר המועדף עליך',
	previewTheProductLabel: 'תצוגה מקדימה של המוצר',
	nextImageLabel: 'התמונה הבאה',
	previousImageLabel: 'התמונה הקודמת',
	productImageLabel: 'תמונת המוצר',
	acceptLabel: 'קבל',
	closeImagePreviewLabel: 'סגור תצוגה מקדימה של תמונה',
	'submit-comment': 'שלח תגובה',
	'reset-comments': 'אפס תגובות',
	or: 'או',
	'get-started-with-questions': 'כמה שאלות לשם התחלה',
	'increase-ranking': 'הגבר דירוג עבור {{label}}',
	'decrease-ranking': 'הפחת דירוג עבור {{label}}',
	continue: 'המשך',
	retry: 'נסה שוב',
	'tell-us-how-you-feel-about-this-product': 'ספר לנו מה אתה מרגיש לגבי מוצר זה',
	begin: 'התחל',
	'how-would-you-describe-this-product-to-a-friend': 'איך היית מתאר מוצר זה לחבר?',
	'type-your-response-here': 'הקלד את תגובתך כאן ...',
	'sum-of-total-characters': '${SUM} מתוך ${TOTAL} תווים',
	edit: 'ערוך',
	remove: 'הסר',
	'how-do-you-feel-about-this-part': 'איך אתה מרגיש לגבי החלק הזה?',
	'tap-on-an-area-and-choose-an-emoji': "הקש על אזור ובחר אמוג'י",
	'im-finished': 'סיימתי',
	maintenance: 'Maintenance',
	'maintenance-mode': 'Upsiide is currently undergoing maintenance. Please check back soon.',
	'please-specify': 'אנא פרט',
	'monadic-split-statement':
		'This is placeholder text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis laoreet libero et neque volutpat volutpat. Maecenas ultrices purus ex, commodo.',
	disapproval: 'Disapproval',
	anger: 'Anger',
	sad: 'Sad',
	joy: 'Joy',
	love: 'Love',
	trust: 'Trust',
	hope: 'Hope',
	proud: 'Proud',
	amazed: 'Amazed',
	surprised: 'Surprised',
	guilt: 'Guilt',
	curious: 'Curious',
	jealous: 'Jealous',
	anticipation: 'Anticipation',
	optimistic: 'Optimistic',
	anxious: 'Anxious',
	fear: 'Fear',
	despair: 'Despair',
	disbelief: 'Disbelief',
	cynicism: 'Cynicism',
	regret: 'Regret',
	disgust: 'Disgust',
	annoyed: 'Annoyed',
	aggressive: 'Aggressive',
	shocked: 'Shocked',
	choose: 'Choose {{amount}}',
	'up-to': 'Choose up to {{amount}}',
	unlimited: "Choose as many as you'd like",
	'single-select': 'Choose one',
	number: 'Enter a number',
	'number-between-min-max': 'Enter a number between {{min}} and {{max}}',
	'number-min': 'Enter a number {{min}} or greater',
	'number-max': 'Enter a number {{max}} or less',
	'open-ended-pledge':
		'This survey contains questions where you will be asked to answer in your own words. Your responses will be automatically checked to make sure they meet our quality requirements. Do you agree to continue? ',
	'open-ended-pledge-accept': 'Yes, I agree',
	'open-ended-pledge-decline': 'No, I disagree',
	'rh-1-label': 'Which of the following is not a sport?',
	'rh-1-o-1': 'Basketball',
	'rh-1-o-2': 'Tennis',
	'rh-1-o-3': 'Cookie',
	'rh-1-o-4': 'Hockey',
	'rh-2-label': 'What is 2+3?',
	'rh-2-o-1': '4',
	'rh-2-o-2': '5',
	'rh-2-o-3': '6',
	'rh-2-o-4': '7',
	'rh-3-label': 'Which of the following is a fruit?',
	'rh-3-o-1': 'Milk',
	'rh-3-o-2': 'Chocolate',
	'rh-3-o-3': 'Egg',
	'rh-3-o-4': 'Apple',
	'open-end-max-length': 'Sorry, entered answer is too long',
	'heatmap-click-image': 'Click on the image to add a comment',
	'heatmap-click-image-no-comments': 'Click anywhere on the image',
	'heatmap-click-image-product-card': "Click where you'd like to leave a comment",
	'heatmap-click-image-product-card--no-comment': 'Click anywhere on the card',
	'heatmap-type-comment': 'Type a comment',
	'heatmap-add-comment': 'הוסף תגובה',
	cancel: 'Cancel',
	skip: 'Skip',
	ranked: 'מדורג',
	top: 'העליון',
	'tap-or-drag': 'הקש או גרור ושחרר כדי לדרג',
	unranked: 'לא מדורג',
	reset: 'אִתחוּל',
	'no-more-options': 'אין יותר אפשרויות לדירוג',
	'rank-list-full': 'רשימת הדירוג מלאה, בטל דירוג של אחת מהבחירות שלך',
	'expanded-image': 'תמונה מורחבת',
	'expand-the-image': 'הרחב את התמונה',
	'type-here': 'הקלד כאן',
	'continue-survey': 'המשך סקר',
	'place-a-point-to-comment': 'הנח נקודה להערה',
};
