// React & Redux
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Animation
import { motion } from 'framer-motion';

import * as actions from 'src/domains/main/actions';
import * as misc from 'src/utilities/misc';
import api from 'src/utilities/api';
import Loader from 'src/components/elements/Loader';
import { initDataDogRum } from '../../../../utilities/dataDogRum';

// Containers
import Start from '../Start';
import Welcome from '../Welcome';
import CustomQuestions from '../CustomQuestions';
import Questions from '../Questions';
import MonadicSplit from '../MonadicSplit';
import Survey from '../Survey';
import TextAI from '../TextAI';
import End from '../End';
import Terminated from '../Terminated';
import OpenEndedPledge from '../OpenEndedPledge';
import RedHerring from '../RedHerring';
import LinkRouting from '../LinkRouting';
import * as selectors from '../../selectors';

// Components

import './styles.scss';
import VideoPermissionChecker from '../../components/Question/VideoGuidedQuestion/components/VideoPermissionChecker';

const loaderWrap = {
	start: {
		y: 50,
		opacity: 0,
	},
	completed: {
		y: 0,
		opacity: 1,
	},
};

// Main Container
const Main = ({ ...props }) => {
	const { step, currentSectionIndex, sections, validateRespondentLoading, requiredVideoQuestions } = useSelector(
		(state) => selectors.getMainState(state),
	);
	const study = useSelector((state) => selectors.getStudy(state));
	const answers = useSelector((state) => selectors.getAnswers(state));

	const currentSection = sections[currentSectionIndex];
	const [pose, setPose] = useState('start');
	const [isVideoPermissionChecker, setIsVideoPermissionChecker] = useState(true);
	const videosToUploadRef = useRef([]);
	const dispatch = useDispatch();

	const urlParams = misc.getAllUrlParams();

	const getVideoQuestionsFromSections = (sectionType) =>
		sections
			.filter((section) => section?.type === sectionType)
			.map(({ questions }) => questions)
			.flat()
			.filter((quest) => quest?.style === 'guided-video-question');

	const videoQuestions = useMemo(() => {
		const monadicSplitQuestions = getVideoQuestionsFromSections('monadic_split');
		const questionSingle = getVideoQuestionsFromSections('single_question');
		const questionSections = getVideoQuestionsFromSections('questions');
		return [...monadicSplitQuestions, ...questionSingle, ...questionSections];
	}, [sections]);

	const sendVideoToApi = (videoToUpload) => {
		videosToUploadRef.current.push(videoToUpload);
	};

	const onSkip = () => {
		window.sessionStorage.setItem('skipVideoQuestion', 'true');
		setIsVideoPermissionChecker(false);
	};

	const loading = (
		<div className="main__loader">
			<motion.div animate={pose} variants={loaderWrap} initial="start">
				<Loader />
			</motion.div>
		</div>
	);

	useEffect(() => {
		setPose('completed');
	}, []);

	if (step === 'start') {
		return <Start {...props} />;
	}
	if (step === 'loading') return loading;
	if (step === 'sections' && validateRespondentLoading && currentSection.type !== 'red-herring') {
		return loading;
	}

	if (step === 'sections') {
		let sectionsRemainingContainsVQ = false;
		const remainingSections = sections.slice(currentSectionIndex);
		remainingSections?.forEach((sec) => {
			sec?.questions?.forEach((q) => {
				if (q?.style === 'guided-video-question') {
					sectionsRemainingContainsVQ = true;
				}
			});
		});
		let skippedVideoQuestions = false;
		Object.keys(answers).forEach((key) => {
			if (answers[key]?.value === 'SKIP' && answers[key]?.type === 'guided-video-question') {
				skippedVideoQuestions = true;
			}
		});

		if (
			isVideoPermissionChecker &&
			videoQuestions.length > 0 &&
			sectionsRemainingContainsVQ &&
			!skippedVideoQuestions
		) {
			// enable dd rum TODO - REMOVE THIS LATER
			if (process?.env?.production || process?.env?.staging) {
				initDataDogRum();
			}
			return (
				<VideoPermissionChecker
					onProceed={() => setIsVideoPermissionChecker(false)}
					disqualifyOnReject={!!requiredVideoQuestions?.length}
					onSkip={onSkip}
					question={videoQuestions?.[0]}
					currentSection={currentSection}
				/>
			);
		}
		const text = currentSection?.statements?.[0]?.text;
		switch (currentSection.type) {
			case 'red-herring':
				return <RedHerring {...props} />;
			case 'open-ended-pledge':
				return <OpenEndedPledge {...props} />;
			case 'monadic_split':
				return <MonadicSplit {...props} sendVideoToApi={sendVideoToApi} />;
			case 'custom':
				return <CustomQuestions {...props} />;
			case 'statement':
				if (text && text !== '<p><br></p>') {
					return <Welcome {...props} />;
				}
				return dispatch(actions.setNextSection());
			case 'questions':
				return <Questions {...props} sendVideoToApi={sendVideoToApi} />;
			case 'single_question':
				return <Questions {...props} sendVideoToApi={sendVideoToApi} />;
			case 'swipe':
				return <Survey {...props} />;
			case 'text-ai':
				return <TextAI {...props} />;
			case 'link_routing':
				if (
					currentSection.linkRouting &&
					currentSection.linkRouting[0]?.loi &&
					currentSection.linkRouting[0]?.url
				) {
					return <LinkRouting {...props} />;
				}
				dispatch(actions.setNextSection());

			default:
				return null;
		}
	} else if (step === 'terminated') {
		return <Terminated {...props} />;
	} else if (step === 'end') {
		return <End {...props} videosToUpload={videosToUploadRef.current} />;
	} else {
		return null;
	}
};

export default Main;
