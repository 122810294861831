import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { UPThemeProvider } from '@upsiide/ui-components';
import DevicePreviewHeader from 'src/components/new/DevicePreviewHeader';
import MainContainer from 'src/components/new/MainContainer';
import MaintenanceMode from 'src/domains/main/pages/MaintenanceMode';
import { FooterProvider } from 'src/components/helpers/Footer';
import * as misc from 'src/utilities/misc';
import DomainRoutes from 'src/domains/routes';
import i18n from 'src/i18n';
import ScrollContainer from './components/shared/ScrollContainer';
import { ScrollProvider } from './components/helpers/ScrollProvider';
import ScrollClassWrapper from './components/helpers/ScrollClassWrapper';

class App extends React.Component {
	constructor() {
		super();
		this.state = {
			className: '',
		};
		this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
	}

	componentDidMount() {
		window.addEventListener('changeLanguage', this.handleChangeLanguage);
	}

	componentWillUnmount() {
		window.removeEventListener('changeLanguage', this.handleChangeLanguage);
	}

	handleChangeLanguage() {
		if (i18n.isRTL()) {
			this.setState({ className: 'rtl' });
		}
	}

	render() {
		const urlParams = misc.getAllUrlParams();

		if (process.env.maintenanceMode && !urlParams.skipMaintenance) {
			return (
				<div className="maintenance-container">
					<MaintenanceMode />
				</div>
			);
		}
		return (
			<MainContainer className={this.state.className}>
				{/* // * Hidden `h1` for the accessibility best practices */}
				<h1 className="accessibility-h1">Upsiide</h1>

				<UPThemeProvider>
					<ScrollProvider>
						<FooterProvider>
							<DevicePreviewHeader />
							<ScrollClassWrapper>
								<ScrollContainer>
									<BrowserRouter>
										<DomainRoutes path="/*" />
									</BrowserRouter>
								</ScrollContainer>
							</ScrollClassWrapper>
						</FooterProvider>
					</ScrollProvider>
				</UPThemeProvider>
			</MainContainer>
		);
	}
}

export default App;
